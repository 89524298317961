import { Form, Input, Button, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 10,
  },
}

function ConsentSurveyFormMp3(props) {
  const { onSurveyStatusSubmit, data } = props
  const [form] = Form.useForm()
  const [, forceUpdate] = useState() // To disable submit button at the beginning

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleFinish = (values) => {
    onSurveyStatusSubmit(values)
  }
  /* eslint-disable */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  /* eslint-enable */

  return (
    <Form
      form={form}
      {...layout}
      name="nest-messages"
      onFinish={handleFinish}
      validateMessages={validateMessages}
      initialValues={{
        id: data?.id,
        emailDate: data?.emailDate && moment(data?.emailDate),
        smsDate: data?.smsDate && moment(data?.smsDate),
        callDate: data?.callDate && moment(data?.callDate),
        endDate: data?.endDate && moment(data?.endDate),
        invitationDate: data?.invitationDate && moment(data?.invitationDate),
      }}
    >
      <Form.Item name="id" label="id" hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item label="Invitation Sent" name={['invitationDate']}>
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>
      <Form.Item label="Reminder SMS#1 Sent" name={['emailDate']}>
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>
      <Form.Item label="Reminder SMS#2 Sent" name={['smsDate']}>
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>
      <Form.Item label="Reminder Call Made" name={['callDate']}>
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>
      <Form.Item label="Completion Date" name={['endDate']}>
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} shouldUpdate>
        {() => (
          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                (!form.isFieldTouched('emailDate') &&
                  !form.isFieldTouched('callDate') &&
                  !form.isFieldTouched('invitationDate') &&
                  !form.isFieldTouched('endDate') &&
                  !form.isFieldTouched('smsDate')) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Submit
            </Button>
          </ButtonContainer>
        )}
      </Form.Item>
    </Form>
  )
}
export default ConsentSurveyFormMp3
