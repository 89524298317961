import EP from './endpoints'
import api from './api'

export function createContact(type, participantId, data) {
  return api.post(EP.PARTICIPANT_ADD_CONTACT(type, participantId), data)
}

export function createContactDetail(id, data) {
  return api.post(EP.PARTICIPANT_ADD_CONTACT_DETAIL(id), data)
}

export function createPrimaryContact(type, participantId, data) {
  return api.post(EP.PARTICIPANT_ADD_CONTACT(type, participantId), data)
}

export function createPrimaryContactDetail(id, data) {
  return api.post(EP.PARTICIPANT_ADD_CONTACT_DETAIL(id), data)
}

export function editContact(id, data) {
  return api.put(EP.PARTICIPANT_EDIT_CONTACT_DETAIL(id), data)
}
export function createParticipantContactDetail(id, data) {
  return api.post(EP.PARTICIPANT_ADD_PARTICIPANT_CONTACT_DETAIL(id), data)
}

export function makeOtherContactPrimary(id, data) {
  return api.patch(EP.PARTICIPANT_MAKE_OTHER_CONTACT_PRIMARY(id), data)
}
