import { Form, Input, Button, Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 10,
  },
}

function EditContactForm(props) {
  const { onContactEditSubmit, data } = props
  const [form] = Form.useForm()
  const [, forceUpdate] = useState() // To disable submit button at the beginning
  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleFinish = (values) => {
    onContactEditSubmit({ ...values, id: data.id })
  }
  /* eslint-disable */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  /* eslint-enable */

  return (
    <Form
      form={form}
      name="nest-messages"
      validateMessages={validateMessages}
      onFinish={handleFinish}
      initialValues={{
        id: data?.id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        relationship: data?.relationship,
      }}
    >
      <Row gutter={24}>
        <Col span={12} key={1}>
          <Form.Item
            name={['firstName']}
            label="First Name"
            rules={[
              {
                // required: true,
                initialValue: data?.firstName,
              },
            ]}
          >
            <Input placeholder="Please input FirstName" />
          </Form.Item>
        </Col>
        <Col span={12} key={2}>
          <Form.Item
            name={['lastName']}
            label="Last Name"
            rules={[
              {
                // required: true,
                initialValue: data?.lastName,
              },
            ]}
          >
            <Input placeholder="Please input LastName" />
          </Form.Item>
        </Col>
        <Col span={12} key={3}>
          <Form.Item
            name={['relationship']}
            label="Relationship"
            rules={[
              {
                // required: true,
                initialValue: data?.relationship,
              },
            ]}
          >
            <Input placeholder="Please input Relationship" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} shouldUpdate>
        {() => (
          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                (!form.isFieldTouched('firstName') &&
                  !form.isFieldTouched('lastName') &&
                  !form.isFieldTouched('relationship')) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Submit
            </Button>
          </ButtonContainer>
        )}
      </Form.Item>
    </Form>
  )
}
export default EditContactForm
