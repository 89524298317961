import React, { useState } from 'react'
import { Row, Col, Card } from 'antd'
import UploadParticipantDistributionHistory from '@components/upload'
import UploadParentDistributionHistory from '@components/upload'
import UploadRecallingSentence from '@components/upload'
import UploadParentMP1 from '@components/upload'
import UploadParticipantMP1 from '@components/upload'
import { setParentDistribution } from '@actions/parentActions'
import { setParticipantDistribution } from '@actions/participantActions'
import { setRecallingSentenceDistributionHistory } from '@actions/recallingSentenceActions'
import { setParentMPOneDistribution } from '@actions/parentMPOneActions'
import { setParticipantMPOneDistribution } from '@actions/participantMPOneActions'
import connect from 'react-redux/es/connect/connect'
import { setParticipantMPTwoDistribution } from '@actions/participantMPTwoActions'
import { setParentMPTwoDistribution } from '@actions/parentMPTwoActions'
import { setParticipantMPThreeDistribution } from '@actions/participantMPThreeActions'

function DistributionHistoryContainer(props) {
  const {
    // State
    loading,
    // Dispatch
    setParticipantDistribution,
    setParentDistribution,
    setRecallingSentenceDistributionHistory,
    setParentMPOneDistribution,
    setParticipantMPOneDistribution,
    setParticipantMPTwoDistribution,
    setParentMPTwoDistribution,
    setParticipantMPThreeDistribution,
  } = props

  //eslint-disable-next-line
  const [participantDistributionSubmit, setParticipantDistributionSubmit] = useState(
    false
  )
  //eslint-disable-next-line
  const [parentDistributionSubmit, setParentDistributionSubmit] = useState(false)
  //eslint-disable-next-line
  const [recallSentenceDistributionSubmit, setRecallSentenceDistributionSubmit] = useState(false)

  //eslint-disable-next-line
  const [parentMP1DistributionSubmit, setParentMP1DistributionSubmit] = useState(
    false
  )
  //eslint-disable-next-line
  const [participantMP1DistributionSubmit, setParticipantMP1DistributionSubmit] = useState(false)
  const onUploadParticipantDistribution = (file) => {
    setParticipantDistribution(file)
      .then(() => setParticipantDistributionSubmit(true))
      .catch(() => setParticipantDistributionSubmit(false))
  }

  const onUploadParentDistribution = (file) => {
    setParentDistribution(file)
      .then(() => setParentDistributionSubmit(true))
      .catch(() => setParentDistributionSubmit(false))
  }

  const onUploadRecallingSentenceDistributionHistory = (file) => {
    setRecallingSentenceDistributionHistory(file)
      .then(() => setRecallSentenceDistributionSubmit(true))
      .catch(() => setRecallSentenceDistributionSubmit(false))
  }

  const onUploadParentMP1Distribution = (file) => {
    setParentMPOneDistribution(file)
      .then(() => setParentMP1DistributionSubmit(true))
      .catch(() => setParentMP1DistributionSubmit(false))
  }

  const onUploadParticipantMP1Distribution = (file) => {
    setParticipantMPOneDistribution(file)
      .then(() => setParticipantMP1DistributionSubmit(true))
      .catch(() => setParticipantMP1DistributionSubmit(false))
  }
  const onUploadParticipantMP2Distribution = (file) => {
    setParticipantMPTwoDistribution(file)
      .then(() => setParticipantMP1DistributionSubmit(true))
      .catch(() => setParticipantMP1DistributionSubmit(false))
  }

  //eslint-disable-next-line
  const onUploadParentMP2Distribution = (file) => {
    setParentMPTwoDistribution(file)
      .then(() => setParentMP1DistributionSubmit(true))
      .catch(() => setParentMP1DistributionSubmit(false))
  }

  const onUploadParticipantMP3Distribution = (file) => {
    setParticipantMPThreeDistribution(file)
      .then(() => setParticipantMP1DistributionSubmit(true))
      .catch(() => setParticipantMP1DistributionSubmit(false))
  }

  return (
    <div>
      <div
        style={{
          fontWeight: '700',
          fontSize: '22px',
          backgroundColor: '#f4f4f4',
          color: '#333333',
        }}
      >
        Survey / Task Linkage Utility
      </div>
      <div className="flex-container">
        <div>
          <Col>
            <Card
              title="Parent's Consent Distribution History"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
              style={{ height: '450px' }}
            >
              <Row type="flex" align="left" style={{ paddingTop: '10px' }}>
                <Col md={{ span: 24 }}>
                  <div style={{ paddingTop: '10px' }}>
                    <UploadParentDistributionHistory
                      name="parent-distribution"
                      labelType="PARENT"
                      onUploadClick={onUploadParentDistribution}
                      loading={loading}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
        <div>
          <Col>
            <Card
              title="Participant's Consent Distribution History"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
              style={{ height: '450px' }}
            >
              <Row type="flex" align="left" style={{ paddingTop: '10px' }}>
                <Col md={{ span: 24 }}>
                  <div style={{ paddingTop: '10px' }}>
                    <UploadParticipantDistributionHistory
                      name="participant-distribution"
                      labelType="PARTICIPANT"
                      onUploadClick={onUploadParticipantDistribution}
                      loading={loading}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
        <div>
          {' '}
          <Col>
            <Card
              title="Recalling Sentence Task Ids"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
              style={{ height: '450px' }}
            >
              <Row type="flex" align="left" style={{ paddingTop: '10px' }}>
                <Col md={{ span: 24 }}>
                  <div style={{ paddingTop: '10px' }}>
                    <UploadRecallingSentence
                      name="recall-sentence-distribution"
                      labelType="RECALLING"
                      onUploadClick={onUploadRecallingSentenceDistributionHistory}
                      loading={loading}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
        <div>
          {' '}
          <Col>
            <Card
              title="Parent - Measures Package 1"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
              style={{ height: '450px' }}
            >
              <Row type="flex" align="left" style={{ paddingTop: '10px' }}>
                <Col md={{ span: 24 }}>
                  <div style={{ paddingTop: '10px' }}>
                    <UploadParentMP1
                      name="parent-mp1-distribution"
                      labelType="PARENT"
                      onUploadClick={onUploadParentMP1Distribution}
                      loading={loading}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
        <div>
          <Col>
            <Card
              title="Participant - Measures Package 1"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
              style={{ height: '450px' }}
            >
              <Row type="flex" align="left" style={{ paddingTop: '10px' }}>
                <Col md={{ span: 24 }}>
                  <div style={{ paddingTop: '10px' }}>
                    <UploadParticipantMP1
                      name="participant-mp1-distribution"
                      labelType="PARTICIPANT"
                      onUploadClick={onUploadParticipantMP1Distribution}
                      loading={loading}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
        <div>
          <Col>
            <Card
              title="Participant - Measures Package 2"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
              style={{ height: '450px' }}
            >
              <Row type="flex" align="left" style={{ paddingTop: '10px' }}>
                <Col md={{ span: 24 }}>
                  <div style={{ paddingTop: '10px' }}>
                    <UploadParticipantMP1
                      name="participant-mp2-distribution"
                      labelType="PARTICIPANT"
                      onUploadClick={onUploadParticipantMP2Distribution}
                      loading={loading}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
        <div>
          <Col>
            <Card
              title="Participant - Measure Package 3"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
              style={{ height: '450px' }}
            >
              <Row type="flex" align="left" style={{ paddingTop: '10px' }}>
                <Col md={{ span: 24 }}>
                  <div style={{ paddingTop: '10px' }}>
                    <UploadParticipantMP1
                      name="participant-mp3-distribution"
                      labelType="PARTICIPANT"
                      onUploadClick={onUploadParticipantMP3Distribution}
                      loading={loading}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ combineReducer, user }) => ({
  user: user.user,
  loading: combineReducer.loading,
})

const mapDispatchToProps = {
  setParentDistribution,
  setParticipantDistribution,
  setRecallingSentenceDistributionHistory,
  setParentMPOneDistribution,
  setParticipantMPOneDistribution,
  setParticipantMPTwoDistribution,
  setParentMPTwoDistribution,
  setParticipantMPThreeDistribution,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionHistoryContainer)
