import { produce } from 'immer'
import { PROMISE_STATUS } from '@constants/strings'
import { GENERAL, PARENT, USER } from '../actionTypes'

const initialState = {
  user: null,
  users: null,
  new_password: null,
  isLogged: false,
  loading: {
    fetchAll: false,
    downloadWorksheet: false,
  },
}

// eslint-disable-next-line consistent-return
function reducer(state = initialState, action) {
  switch (action.type) {
    case `${USER.REGISTER_START}_${PROMISE_STATUS.PENDING}`:
      state.isLogged = false
      break
    case `${USER.REGISTER_START}_${PROMISE_STATUS.FULFILLED}`:
      state.user = action.payload
      state.isLogged = false
      break
    case `${USER.REGISTER_START}_${PROMISE_STATUS.REJECTED}`: {
      state.isLogged = false
      break
    }
    case `${USER.SET_LOGOUT_USER}_${PROMISE_STATUS.PENDING}`:
      state.isLogged = false
      break
    case `${USER.SET_LOGOUT_USER}_${PROMISE_STATUS.FULFILLED}`:
      state.user = null
      state.participant = null
      state.isLogged = false
      break
    case `${USER.SET_LOGOUT_USER}_${PROMISE_STATUS.REJECTED}`: {
      state.isLogged = false
      break
    }
    case `${USER.SET_LOGIN_USER}_${PROMISE_STATUS.FULFILLED}`:
      state.user = action.payload
      state.isLogged = true
      break
    case `${USER.SET_LOGIN_USER}_${PROMISE_STATUS.PENDING}`:
      state.isLogged = false
      break
    case `${USER.SET_LOGIN_USER}_${PROMISE_STATUS.REJECTED}`: {
      state.isLogged = false
      break
    }
    case `${USER.SET_ALL_USERS}_${PROMISE_STATUS.FULFILLED}`: {
      state.users = action.payload
      state.loading.fetchAll = false
      break
    }
    case `${USER.SET_ALL_USERS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${USER.SET_ALL_USERS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${USER.SET_NEW_USER}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.users.splice(0, 0, payload)
      break
    }
    case `${USER.SET_NEW_USER}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${USER.SET_NEW_USER}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${USER.UPDATE_USER_DETAIL}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      const index = state.users.findIndex((item) => item.id === payload.id)
      state.users[index] = payload
      break
    }
    case `${USER.UPDATE_USER_DETAIL}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${USER.UPDATE_USER_DETAIL}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${USER.UPDATE_USER_OWN_PASSWORD}_${PROMISE_STATUS.FULFILLED}`: {
      break
    }
    case `${USER.UPDATE_USER_OWN_PASSWORD}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${USER.UPDATE_USER_OWN_PASSWORD}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${USER.UPDATE_OTHER_USER_PASSWORD}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.new_password = payload.new_password
      break
    }
    case `${USER.UPDATE_OTHER_USER_PASSWORD}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${USER.UPDATE_OTHER_USER_PASSWORD}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARENT.SET_DOWNLOAD_BULK_WORKSHEET}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARENT.SET_DOWNLOAD_BULK_WORKSHEET}_${PROMISE_STATUS.PENDING}`: {
      state.loading.downloadWorksheet = true
      break
    }
    case `${PARENT.SET_DOWNLOAD_BULK_WORKSHEET}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case GENERAL.RESTORE_STATE:
      state.user = action.data
      state.isLogged = true
      break
    default:
      return state
  }
}

export default produce(reducer, initialState)
