import EP from './endpoints'
import api from './api'

export function editComment(id, data) {
  return api.put(EP.PARTICIPANT_UPDATE_COMMENT(id), data)
}

export function createComment(userId, participantId, data) {
  return api.post(EP.PARTICIPANT_ADD_COMMENT(userId, participantId), data)
}

export function deleteAComment(id) {
  return api.delete(EP.PARTICIPANT_DELETE_COMMENT(id))
}
