import { PARENT_MP1 } from '@store/actionTypes'
import {
  uploadDistribution,
  fetchAllParentMPOne,
  uploadConsent,
  uploadCorrespondenceHistory,
  downloadParentMPOneWorksheet,
  editSurveyStatus,
  deleteSurveyStatus,
} from '@api/parentMPOne'
import { PARENT_MP1_MSGS } from '@constants/strings'
import { get } from 'lodash'
import { message } from 'antd'
import moment from 'moment'

/**
 * Update Parent Measure Package 1 uploaded distribution History file
 */
export const setParentMPOneDistribution = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARENT_MP1.SET_UPLOAD_PARENT_MP1_DISTRIBUTION_HISTORY,
    async payload() {
      const resp = await uploadDistribution('parentMp1', formData)
      return resp.data
    },
  })

  return response
    .then(() => {
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get Parent Measure Package 1 List
 */
export const getMPOneParent = () => {
  return {
    type: PARENT_MP1.SET_PARENT_MP1_CONSENTS,
    async payload() {
      const resp = await fetchAllParentMPOne()
      return resp.data
    },
  }
}

/**
 * Update Parent Measure Package 1 uploaded Consent file
 */
export const setParentMPOneConsent = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARENT_MP1.SET_UPLOAD_PARENT_MP1_CONSENT,
    async payload() {
      const resp = await uploadConsent('parentMp1', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPOneParent())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Parent Measure Package 1 uploaded Correspondence History file
 */

export const setParentMPOneCorrespondenceHistory = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARENT_MP1.SET_UPLOAD_PARENT_MP1_CORR_HISTORY,
    async payload() {
      const resp = await uploadCorrespondenceHistory('parentMp1', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPOneParent())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Update Parent Measure Package 1 Survey Contact Status i.e: EmailSent, SMSSent, callMade
 */
export const updateParentMP1ContactStatus = (data) => (dispatch) => {
  const response = dispatch({
    type: PARENT_MP1.UPDATE_PARENT_MP1_SURVEY_CONTACT_STATUS,
    async payload() {
      const resp = await editSurveyStatus(data.id, {
        emailDate: data.emailDate !== null ? moment(data.emailDate).format() : null,
        smsDate: data.smsDate !== null ? moment(data.smsDate).format() : null,
        callDate: data.callDate !== null ? moment(data.callDate).format() : null,
        invitationDate:
          data.invitationDate !== null ? moment(data.invitationDate).format() : null,
        endDate: data.endDate !== null ? moment(data.endDate).format() : null,
      })
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPOneParent())
      message.success(
        get(
          response,
          'message',
          PARENT_MP1_MSGS.EDIT_PARENT_MP1_CONTACT_STATUS_SUCCESS_MSG
        )
      )
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Delete Parent Measure Package 1 Survey Survey i.e:   responseId, completionDate
 */
export const deleteParentMP1ConsentSurvey = (id) => (dispatch) => {
  const response = dispatch({
    type: PARENT_MP1.DELETE_PARENT_MP1_SURVEY_CONSENT_SURVEY,
    async payload() {
      const resp = await deleteSurveyStatus(id, 'parentMp1')
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPOneParent())
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get Parent Measure Package 1 Data Worksheet Download
 */

export const getParentMPOneWorkSheetDownload = (data) => (dispatch) => {
  let payload
  data.length > 0
    ? (payload = {
        participantUuids: data,
      })
    : (payload = {})

  const response = dispatch({
    type: PARENT_MP1.SET_PARENT_MP1_DOWNLOAD_FILE,
    async payload() {
      const resp = await downloadParentMPOneWorksheet('parentMp1', payload)
      const fileName = resp.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]/g, '')
      const fileDownload = require('js-file-download')
      fileDownload(resp.data, fileName)
      return resp.data
    },
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
