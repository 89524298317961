import axios from 'axios'
import { get } from 'lodash'
import { message } from 'antd'
import { getStorage } from '@utils/storage'

/**
 * Process successful responses
 * Fill message and status if not returned from the backend
 * @param resp
 * @param delayTime
 * @param displayMessage
 * @returns {{data: *, success: boolean, message: *, status: *}}
 */

async function responseHandler(resp, delayTime, displayMessage) {
  const messageToShow = get(resp.data, 'message')
  // const requestMethod = get(resp, 'config.method')
  if (messageToShow) {
    message.success(messageToShow)
  }
  // 200ms timeout to show loading component
  // await new Promise(resolve => setTimeout(resolve, delayTime || 200))
  return {
    data: (resp.data && resp.data.data) || resp.data,
    headers: get(resp, 'resp.headers', resp.headers),
    message: get(resp.data, 'message', resp.statusText),
    status: get(resp, 'status', resp.status),
    success: true,
  }
}

/**
 * Process failed responses
 * @param {AxiosError} e
 */
function errorHandler(e) {
  const resp = e.response.data
  const errorMessage =
    typeof e.response.data === 'object'
      ? JSON.stringify(get(resp, 'message', 'UNKNOWN_ERROR'))
      : get(resp, 'message', 'UNKNOWN_ERROR')
  message.error(errorMessage)
  throw errorMessage
}

const api = {
  patch: async (url, data = undefined, config = {}) => {
    axios.defaults.headers.common.Authorization =
      'Bearer ' + getStorage('accessToken')
    return axios.patch(url, data, config).then(responseHandler).catch(errorHandler)
  },
  delete: async (url, body = {}) => {
    axios.defaults.headers.common.Authorization =
      'Bearer ' + getStorage('accessToken')
    return axios
      .delete(url, { data: body })
      .then(responseHandler)
      .catch(errorHandler)
  },
  post: async (url, data = undefined, config = {}) => {
    axios.defaults.headers.common.Authorization =
      'Bearer ' + getStorage('accessToken')
    return axios.post(url, data, config).then(responseHandler).catch(errorHandler)
  },
  get: async (url, config = {}, delayTime, displayMessage = true) => {
    //FIXME: remove hardcoded JWT. This has be supplied dynamically from local storage after user logs in.
    axios.defaults.headers.common.Authorization =
      'Bearer ' + getStorage('accessToken')
    return axios
      .get(url, config)
      .then((resp) => responseHandler(resp, delayTime, displayMessage))
      .catch(errorHandler)
  },
  put: async (url, data = undefined, config = {}, delayTime, displayMessage) => {
    axios.defaults.headers.common.Authorization =
      'Bearer ' + getStorage('accessToken')
    return axios
      .put(url, data, config)
      .then((resp) => responseHandler(resp, delayTime, displayMessage))
      .catch(errorHandler)
  },
}

export default api
