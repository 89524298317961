import EP from './endpoints'
import api from './api'

export function fetchAllParticipant() {
  return api.get(EP.ALL_PARTICIPANTS())
}
export function fetchOneParticipant(id) {
  return api.get(EP.PARTICIPANTS_BY_ID(id))
}

export function fetchAllParticipantConsents() {
  return api.get(EP.ALL_PARTICIPANT_CONSENTS())
}
export function uploadDistribution(type, data) {
  return api.post(EP.UPLOAD_DISTRIBUTION_HISTORY(type), data)
}

export function uploadConsent(type, data) {
  return api.post(EP.UPLOAD_SURVEY_RESPONSE(type), data)
}

export function uploadCorrespondenceHistory(type, data) {
  return api.post(EP.UPLOAD_CORRESPONDENCE_HISTORY(type), data)
}

export function updateParticipantById(id, data) {
  return api.put(EP.PARTICIPANT_UPDATE_DETAIL(id), data)
}
export function downloadParticipantConsentWorksheet(type, data) {
  return api.post(EP.DOWNLOAD_WORK_SHEET(type), data)
}

export function editSurveyStatus(id, data) {
  return api.patch(EP.UPDATE_BASE_SURVEY_STATUS(id), data)
}

export function deleteSurveyStatus(id, responseType) {
  return api.delete(EP.DELETE_BASE_SURVEY_STATUS(id, responseType))
}
