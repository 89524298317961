import { PARTICIPANT_MP3 } from '@store/actionTypes'
import {
  uploadDistribution,
  fetchAllParticipantMPThree,
  uploadConsent,
  uploadCorrespondenceHistory,
  downloadParticipantMPThreeWorksheet,
  editSurveyStatus,
  updateVariables,
  deleteSurveyStatus,
} from '@api/participantMPThree'
import { PARTICIPANT_MP3_MSGS } from '@constants/strings'
import { get } from 'lodash'
import { message } from 'antd'
import moment from 'moment'

/**
 * Update Participant Measure Package 3 uploaded distribution History file
 */
export const setParticipantMPThreeDistribution = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT_MP3.SET_UPLOAD_PARTICIPANT_MP3_DISTRIBUTION_HISTORY,
    async payload() {
      const resp = await uploadDistribution('participantMp3', formData)
      return resp.data
    },
  })

  return response
    .then(() => {
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get Participant Measure Package 3 List
 */
export const getMPThreeParticipant = () => {
  return {
    type: PARTICIPANT_MP3.SET_PARTICIPANT_MP3_CONSENTS,
    async payload() {
      const resp = await fetchAllParticipantMPThree()
      return resp.data
    },
  }
}

/**
 * Update Participant Measure Package 3 uploaded Consent file
 */
export const setParticipantMPThreeConsent = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT_MP3.SET_UPLOAD_PARTICIPANT_MP3_CONSENT,
    async payload() {
      const resp = await uploadConsent('participantMp3', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPThreeParticipant())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant Measure Package 3 uploaded Correspondence History file
 */

export const setParticipantMPThreeCorrespondenceHistory = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT_MP3.SET_UPLOAD_PARTICIPANT_MP3_CORR_HISTORY,
    async payload() {
      const resp = await uploadCorrespondenceHistory('participantMp3', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPThreeParticipant())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Update Participant Measure Package 3 Survey Contact Status i.e: EmailSent, SMSSent, callMade
 */
export const updateParticipantMP3ContactStatus = (data) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT_MP3.UPDATE_PARTICIPANT_MP3_SURVEY_CONTACT_STATUS,
    async payload() {
      const resp = await editSurveyStatus(data.id, {
        emailDate: data.emailDate !== null ? moment(data.emailDate).format() : null,
        smsDate: data.smsDate !== null ? moment(data.smsDate).format() : null,
        callDate: data.callDate !== null ? moment(data.callDate).format() : null,
        invitationDate:
          data.invitationDate !== null ? moment(data.invitationDate).format() : null,
        endDate: data.endDate !== null ? moment(data.endDate).format() : null,
      })
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPThreeParticipant())
      message.success(
        get(
          response,
          'message',
          PARTICIPANT_MP3_MSGS.EDIT_PARTICIPANT_MP3_CONTACT_STATUS_SUCCESS_MSG
        )
      )
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant Measure Package 3 Variables
 */
export const updateParticipantMP3VariablesStatus = (data) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT_MP3.UPDATE_PARTICIPANT_MP3_VARIABLE_STATUS,
    async payload() {
      const resp = await updateVariables(data.id, {
        mbsPbsAirConsent: data.mbsPbsAirConsentStatus,
        mbsPbsAirConsentFormSigned: data.mbsPbsAirConsentFormSigned,
        futureConsentStatus: data.futureResearchConsentStatus,
        ppiConsentStatus: data.ppiConsentStatus,
        permanentAddress2023: data.permanentAddress2023,
        postalAddress2023: data.postalAddress2023,
      })
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPThreeParticipant())
      message.success(
        get(
          response,
          'message',
          PARTICIPANT_MP3_MSGS.EDIT_PARTICIPANT_MP3_VARIABLES_STATUS_SUCCESS_MSG
        )
      )
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * DELETE Participant Measure Package 3 Consent Survey  i.e:  ResponseId, CompletionDate
 */
export const deleteParticipantMP3ConsentSurvey = (id) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT_MP3.DELETE_PARTICIPANT_MP3_SURVEY_CONSENT_SURVEY,
    async payload() {
      const resp = await deleteSurveyStatus(id, 'participantMp3')
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPThreeParticipant())
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Get Participant Measure Package 3 Data Worksheet Download
 */

export const getParticipantMPThreeWorkSheetDownload = (data) => (dispatch) => {
  let payload
  data.length > 0
    ? (payload = {
        participantUuids: data,
      })
    : (payload = {})

  const response = dispatch({
    type: PARTICIPANT_MP3.SET_PARTICIPANT_MP3_DOWNLOAD_FILE,
    async payload() {
      const resp = await downloadParticipantMPThreeWorksheet(
        'participantMp3',
        payload
      )
      const fileName = resp.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]/g, '')
      const fileDownload = require('js-file-download')
      fileDownload(resp.data, fileName)
      return resp.data
    },
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
