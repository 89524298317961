import { Form, Input, Button, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}

function CommentEditForm(props) {
  const { onUpdateCommentSubmit, data } = props

  const [form] = Form.useForm()
  const [, forceUpdate] = useState() // To disable submit button at the beginning

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleFinish = (values) => {
    const modDate = moment(values.date).format('YYYY-MM-DD')
    const modValues = {
      ...values,
      date: modDate,
    }
    onUpdateCommentSubmit(modValues)
  }

  /* eslint-disable */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  /* eslint-enable */
  return (
    <Form
      form={form}
      {...layout}
      name="nest-messages"
      onFinish={handleFinish}
      validateMessages={validateMessages}
      initialValues={{
        id: data?.id,
        note: data?.note,
        date: data?.date && moment(data?.date),
      }}
    >
      <Form.Item name="id" label="id" hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item
        name={['note']}
        label="Note"
        rules={[
          {
            required: true,
            initialValue: data && data.note,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="Date"
        name={['date']}
        rules={[
          {
            required: true,
            // initialValue: data && data.date,
          },
        ]}
      >
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} shouldUpdate>
        {() => (
          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                (!form.isFieldTouched('date') && !form.isFieldTouched('note')) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Submit
            </Button>
          </ButtonContainer>
        )}
      </Form.Item>
    </Form>
  )
}
export default CommentEditForm
