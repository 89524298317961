import { Form, Input, Button, Select, Row, Col, DatePicker } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 10,
  },
}

function SummaryForm(props) {
  const { onParticipantDetailSubmit, data } = props
  const [form] = Form.useForm()
  const [, forceUpdate] = useState() // To disable submit button at the beginning

  useEffect(() => {
    forceUpdate({})
  }, [])
  const handleFinish = (values) => {
    const modDate = moment(values.dob).format('YYYY-MM-DD')
    const modValues = {
      ...values,
      dob: modDate,
    }
    onParticipantDetailSubmit(modValues)
  }

  /* eslint-disable */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  /* eslint-enable */
  return (
    <Form
      form={form}
      name="nest-messages"
      validateMessages={validateMessages}
      onFinish={handleFinish}
      initialValues={{
        id: data?.id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        dob: data?.dob && moment(data?.dob),
        gender: data?.gender,
        genderDescription: data?.genderDescription,
        participantRecruitmentStatus: data?.participantRecruitmentStatus,
        parentRecruitmentStatus: data?.parentRecruitmentStatus,
        status: data?.status,
      }}
    >
      <Row gutter={24}>
        <Col span={12} key={1}>
          <Form.Item name={['firstName']} label="First Name">
            <Input placeholder="Please input Participant First Name" />
          </Form.Item>
        </Col>

        <Col span={12} key={2}>
          <Form.Item name={['lastName']} label="Last Name">
            <Input placeholder="Please input Participant Last Name" />
          </Form.Item>
        </Col>
        <Col span={12} key={3}>
          <Form.Item label="Date of Birth" name={['dob']}>
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </Col>
        <Col span={6} key={4}>
          <Form.Item name={['gender']} label="Gender">
            <Select placeholder="Select Participant Gender">
              <Select.Option value="Female">Female</Select.Option>
              <Select.Option value="Male">Male</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} key={5}>
          <Form.Item name={['genderDescription']} label="Describe Gender">
            <Input placeholder="Please describe Participant Gender" />
          </Form.Item>
        </Col>
        <Col span={24} />
        <Col span={24} key={6}>
          <Form.Item
            name={['participantRecruitmentStatus']}
            label="Participant Recruitment Status"
          >
            <Select placeholder="Select Participant Status">
              <Select.Option value="NOT_ELIGIBLE">NOT_ELIGIBLE</Select.Option>
              <Select.Option value="PARENT_CONSENT_UNKNOWN">
                PARENT_CONSENT_UNKNOWN
              </Select.Option>
              <Select.Option value="DECLINED_BY_PARENT">
                DECLINED_BY_PARENT
              </Select.Option>
              <Select.Option value="ELIGIBLE">ELIGIBLE</Select.Option>
              <Select.Option value="UNCONTACTABLE">UNCONTACTABLE</Select.Option>
              <Select.Option value="APPROACHED">APPROACHED</Select.Option>
              <Select.Option value="DID_NOT_RESPOND">DID_NOT_RESPOND</Select.Option>
              <Select.Option value="DECLINED_BY_TEEN">
                DECLINED_BY_TEEN
              </Select.Option>
              <Select.Option value="RECRUITED">RECRUITED</Select.Option>
              <Select.Option value="WITHDREW_BY_PARENT">
                WITHDREW_BY_PARENT
              </Select.Option>
              <Select.Option value="WITHDREW_BY_TEEN">
                WITHDREW_BY_TEEN
              </Select.Option>
              <Select.Option value="RECRUITED_THEN_UNCONTACTABLE">
                RECRUITED_THEN_UNCONTACTABLE
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} />
        <Col span={24} key={7}>
          <Form.Item
            name={['parentRecruitmentStatus']}
            label="Parent Recruitment Status"
          >
            <Select placeholder="Select Parent Status">
              <Select.Option value="NOT_ELIGIBLE">NOT_ELIGIBLE</Select.Option>
              <Select.Option value="ELIGIBLE">ELIGIBLE</Select.Option>
              <Select.Option value="UNCONTACTABLE">UNCONTACTABLE</Select.Option>
              <Select.Option value="APPROACHED">APPROACHED</Select.Option>
              <Select.Option value="DID_NOT_RESPOND">DID_NOT_RESPOND</Select.Option>
              <Select.Option value="DECLINED">DECLINED</Select.Option>
              <Select.Option value="RECRUITED">RECRUITED</Select.Option>
              <Select.Option value="WITHDREW">WITHDREW</Select.Option>
              <Select.Option value="RECRUITED_THEN_UNCONTACTABLE">
                RECRUITED_THEN_UNCONTACTABLE
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} shouldUpdate>
        {() => (
          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                (!form.isFieldTouched('firstName') &&
                  !form.isFieldTouched('lastName') &&
                  !form.isFieldTouched('dob') &&
                  !form.isFieldTouched('gender') &&
                  !form.isFieldTouched('genderDescription') &&
                  !form.isFieldTouched('participantRecruitmentStatus') &&
                  !form.isFieldTouched('parentRecruitmentStatus')) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Submit
            </Button>
          </ButtonContainer>
        )}
      </Form.Item>
    </Form>
  )
}
export default SummaryForm
