import { USER } from '@store/actionTypes'
import api from '@api'
import {
  registerNewUser,
  logout,
  addNewUser,
  fetchAllUsers,
  editUser,
  resetOtherUserPassword,
  downloadBulkWorksheet,
} from '@api/auth'
import * as storage from '@utils/storage'
import { message } from 'antd'
import { USER_MSG } from '@constants/strings'
import { get } from 'lodash'

export const loginUser = (user) => {
  return {
    type: USER.SET_LOGIN_USER,
    async payload() {
      const resp = await api.auth.login(user.email, user.password)
      const userData = resp.data
      storage.setStorage('elvs_user', userData)
      return userData
    },
  }
}

export const register = (data) => (dispatch) => {
  const response = dispatch({
    type: USER.REGISTER_START,
    async payload() {
      const resp = await registerNewUser(data.email, data.password, data.name)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', USER_MSG.REGISTER_USER_SUCCESS_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

export const logOutUser = () => (dispatch) => {
  const response = dispatch({
    type: USER.SET_LOGOUT_USER,
    async payload() {
      const resp = logout()
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', USER_MSG.LOGOUT_USER_SUCCESS_MSG))
      storage.setStorage('elvs_user', [])
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Get User List
 */
export const getUsers = () => {
  return {
    type: USER.SET_ALL_USERS,
    async payload() {
      const resp = await fetchAllUsers()
      return resp.data
    },
  }
}

export const setNewUser = (data) => (dispatch) => {
  const reqData = {
    notes: data.notes,
    password: data.password,
    name: data.name,
    email: data.email,
    disabled: data.disabled,
  }
  const response = dispatch({
    type: USER.SET_NEW_USER,
    async payload() {
      const resp = await addNewUser(reqData)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', USER_MSG.ADD_NEW_USER_SUCCESS_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update User Detail
 */
export const updateUserDetail = (data) => (dispatch) => {
  const response = dispatch({
    type: USER.UPDATE_USER_DETAIL,
    async payload() {
      const resp = await editUser(data.id, {
        notes: data.notes,
        password: null,
        name: data.name,
        email: data.email,
        disabled: data.disabled,
      })
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', USER_MSG.EDIT_USER_DETAIL_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 *  User change his/her own password
 */
export const updateChangePassword = (data) => (dispatch) => {
  const response = dispatch({
    type: USER.UPDATE_USER_OWN_PASSWORD,
    async payload() {
      const resp = await editUser(data.id, {
        notes: data.notes,
        password: data.password,
        name: data.name,
        email: data.email,
        disabled: data.disabled,
      })
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', USER_MSG.EDIT_USER_OWN_PASSWORD_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 *  User Reset others password
 */
export const updateResetPassword = (data) => (dispatch) => {
  const response = dispatch({
    type: USER.UPDATE_OTHER_USER_PASSWORD,
    async payload() {
      const resp = await resetOtherUserPassword(data.id)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(
        get(response, 'message', USER_MSG.EDIT_OTHER_USER_PASSWORD_MSG)
      )
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get All bulk Data to download
 */

export const getBulkDataToDownload = () => (dispatch) => {
  const response = dispatch({
    type: USER.SET_DOWNLOAD_BULK_WORKSHEET,
    async payload() {
      const resp = await downloadBulkWorksheet()
      const fileName = resp.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]/g, '')
      const fileDownload = require('js-file-download')
      fileDownload(resp.data, fileName)
      return resp.data
    },
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
