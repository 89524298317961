import React from 'react'
import { Descriptions, Divider, Tabs } from 'antd'
import OtherContacts from '@containers/participants/othersContacts'
import Comments from '@containers/participants/comments'
import Actions from '@containers/participants/actions'
import PrimaryContacts from '@containers/participants/primaryContacts'
import SchoolSummary from '@containers/participants/schools'
import ConsentSummary from '@containers/participants/consents'
import ParticipantSummary from '@containers/participants/summary'

export default function ParticipantDetails(props) {
  const {
    participantToDetails,
    user,
    setComment,
    updateComment,
    deleteComment,
    setAction,
    updateAction,
    updateActionComplete,
    setContact,
    setContactDetail,
    setPrimaryContact,
    setPrimaryContactDetail,
    setEditPrimaryContact,
    setEditOtherContact,
    setParticipantContactDetail,
    updateContactAccess,
    setSchoolDetail,
    updateParticipantDetail,
    updateParticipantContactStatus,
    updateParentContactStatus,
    updateRecallingSentenceContactStatus,
    updateParentMP1ContactStatus,
    updateParentMP2ContactStatus,
    updateParticipantMP1ContactStatus,
    updateParticipantMP2ContactStatus,
    updateParticipantMP3ContactStatus,
    updateParticipantMP3VariablesStatus,
    deleteParticipantConsentSurvey,
    deleteParentConsentSurvey,
    deleteRecallingSentenceTask,
    deleteParentMP1ConsentSurvey,
    deleteParentMP2ConsentSurvey,
    deleteParticipantMP1ConsentSurvey,
    deleteParticipantMP2ConsentSurvey,
    deleteParticipantMP3ConsentSurvey,
    parentComponent,
  } = props

  const { TabPane } = Tabs

  function callback(key) {
    // console.log(key)
  }

  const primaryContact = participantToDetails && participantToDetails.primaryContact
  const otherContacts = participantToDetails && participantToDetails.otherContacts
  const comments = participantToDetails && participantToDetails.comments
  const actions = participantToDetails && participantToDetails.actions
  const participantConsentDetails =
    participantToDetails && participantToDetails.participantConsentDetails
  const parentConsentDetails =
    participantToDetails && participantToDetails.parentConsentDetails
  const recallingSentenceDetails =
    participantToDetails && participantToDetails.recallingSentenceDetails

  const parentMp1Details =
    participantToDetails && participantToDetails.parentMp1Details
  const participantMp1Details =
    participantToDetails && participantToDetails.participantMp1Details

  const parentMp2Details =
    participantToDetails && participantToDetails.parentMp2Details
  const participantMp2Details =
    participantToDetails && participantToDetails.participantMp2Details
  const participantMp3Details =
    participantToDetails && participantToDetails.participantMp3Details
  return (
    participantToDetails && (
      <>
        <Tabs onChange={callback} type="card">
          <TabPane tab="Summary" key="1">
            <Descriptions>
              <Descriptions.Item>
                <ParticipantSummary
                  participantToDetails={participantToDetails}
                  user={user}
                  updateParticipantDetail={updateParticipantDetail}
                  setParticipantContactDetail={setParticipantContactDetail}
                  parentComponent={parentComponent}
                />
              </Descriptions.Item>
            </Descriptions>
          </TabPane>

          <TabPane tab="Schools" key="2">
            <Descriptions>
              <Descriptions.Item>
                {' '}
                <SchoolSummary
                  participantToDetails={participantToDetails}
                  user={user}
                  setSchoolDetail={setSchoolDetail}
                  parentComponent={parentComponent}
                />
              </Descriptions.Item>
            </Descriptions>
          </TabPane>
          <TabPane tab="Primary Contacts" key="3">
            <Descriptions>
              <Descriptions.Item>
                <PrimaryContacts
                  primaryContact={primaryContact}
                  participantToDetails={participantToDetails}
                  user={user}
                  setPrimaryContact={setPrimaryContact}
                  setEditPrimaryContact={setEditPrimaryContact}
                  setPrimaryContactDetail={setPrimaryContactDetail}
                />{' '}
              </Descriptions.Item>
            </Descriptions>
          </TabPane>
          <TabPane tab="Other Contacts" key="4">
            <Divider orientation="left">Current Contact Details:</Divider>
            <OtherContacts
              otherContacts={otherContacts}
              participantToDetails={participantToDetails}
              updateContactAccess={updateContactAccess}
              setEditOtherContact={setEditOtherContact}
              user={user}
              setContact={setContact}
              setContactDetail={setContactDetail}
            />
          </TabPane>
          <TabPane tab="Comments" key="5">
            <Comments
              comments={comments}
              participantToDetails={participantToDetails}
              user={user}
              setComment={setComment}
              updateComment={updateComment}
              deleteComment={deleteComment}
            />
          </TabPane>
          <TabPane tab="Actions" key="6">
            <Actions
              actions={actions}
              participantToDetails={participantToDetails}
              user={user}
              setAction={setAction}
              updateAction={updateAction}
              updateActionComplete={updateActionComplete}
            />
          </TabPane>
          <TabPane tab="Surveys/Tasks" key="8">
            <ConsentSummary
              participantConsentDetails={participantConsentDetails}
              parentConsentDetails={parentConsentDetails}
              parentMp1Details={parentMp1Details}
              parentMp2Details={parentMp2Details}
              participantMp1Details={participantMp1Details}
              participantMp2Details={participantMp2Details}
              participantMp3Details={participantMp3Details}
              recallingSentenceDetails={recallingSentenceDetails}
              updateParticipantContactStatus={updateParticipantContactStatus}
              updateParentContactStatus={updateParentContactStatus}
              updateRecallingSentenceContactStatus={
                updateRecallingSentenceContactStatus
              }
              updateParentMP1ContactStatus={updateParentMP1ContactStatus}
              updateParentMP2ContactStatus={updateParentMP2ContactStatus}
              updateParticipantMP1ContactStatus={updateParticipantMP1ContactStatus}
              updateParticipantMP2ContactStatus={updateParticipantMP2ContactStatus}
              updateParticipantMP3ContactStatus={updateParticipantMP3ContactStatus}
              updateParticipantMP3VariablesStatus={
                updateParticipantMP3VariablesStatus
              }
              deleteParticipantConsentSurvey={deleteParticipantConsentSurvey}
              deleteParentConsentSurvey={deleteParentConsentSurvey}
              deleteRecallingSentenceTask={deleteRecallingSentenceTask}
              deleteParentMP1ConsentSurvey={deleteParentMP1ConsentSurvey}
              deleteParentMP2ConsentSurvey={deleteParentMP2ConsentSurvey}
              deleteParticipantMP1ConsentSurvey={deleteParticipantMP1ConsentSurvey}
              deleteParticipantMP2ConsentSurvey={deleteParticipantMP2ConsentSurvey}
              deleteParticipantMP3ConsentSurvey={deleteParticipantMP3ConsentSurvey}
            />
          </TabPane>
        </Tabs>
      </>
    )
  )
}
