import React, { useEffect } from 'react'
import { getParticipant } from '@actions/participantActions'
import {
  getMPThreeParticipant,
  getParticipantMPThreeWorkSheetDownload,
  setParticipantMPThreeConsent,
  setParticipantMPThreeCorrespondenceHistory,
} from '@actions/participantMPThreeActions'
import ParticipantMPThreeList from '@containers/participantMPThree/participantMPThreeList'
import connect from 'react-redux/es/connect/connect'
import useModal from '@src/hooks/useModal'
import { Spin } from 'antd'
import ParticipantModal from '@containers/participantModal'

function ParticipantContainer(props) {
  const {
    loading,
    participantMPThree,
    getParticipant,
    getMPThreeParticipant,
    setParticipantMPThreeConsent,
    setParticipantMPThreeCorrespondenceHistory,
    getParticipantMPThreeWorkSheetDownload,
  } = props

  const { visible, handleVisibility } = useModal()

  useEffect(() => {
    getMPThreeParticipant()
  }, [getMPThreeParticipant])

  return (
    <div>
      <ParticipantModal
        visible={visible}
        handleVisibility={handleVisibility}
        parentComponent={'participant-mp3-consents'}
      />
      <Spin spinning={loading.uploadingSheet}>
        <ParticipantMPThreeList
          loading={loading}
          participantMPThree={participantMPThree}
          getParticipant={getParticipant}
          setParticipantMPThreeConsent={setParticipantMPThreeConsent}
          setParticipantMPThreeCorrespondenceHistory={
            setParticipantMPThreeCorrespondenceHistory
          }
          handleParticipantDetailsDisplay={handleVisibility}
          getParticipantMPThreeWorkSheetDownload={
            getParticipantMPThreeWorkSheetDownload
          }
        />
      </Spin>
    </div>
  )
}

const mapStateToProps = ({ combineReducer, user }) => ({
  participantToDetails: combineReducer.participantToDetails,
  participantMPThree: combineReducer.participantMPThree,
  loading: combineReducer.loading,
  user: user.user,
})

const mapDispatchToProps = {
  getParticipant: (id) => getParticipant(id),
  getMPThreeParticipant,
  getParticipantMPThreeWorkSheetDownload,
  setParticipantMPThreeCorrespondenceHistory,
  setParticipantMPThreeConsent,
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantContainer)
