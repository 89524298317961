import EP from './endpoints'
import api from './api'

export function fetchAllParticipantMPTwo() {
  return api.get(EP.ALL_PARTICIPANT_MP_TWO())
}

export function uploadDistribution(type, data) {
  return api.post(EP.UPLOAD_DISTRIBUTION_HISTORY(type), data)
}

export function uploadConsent(type, data) {
  return api.post(EP.UPLOAD_SURVEY_RESPONSE(type), data)
}

export function uploadCorrespondenceHistory(type, data) {
  return api.post(EP.UPLOAD_CORRESPONDENCE_HISTORY(type), data)
}
export function downloadParticipantMPTwoWorksheet(type, data) {
  return api.post(EP.DOWNLOAD_WORK_SHEET(type), data)
}

export function editSurveyStatus(id, data) {
  return api.patch(EP.UPDATE_BASE_SURVEY_STATUS(id), data)
}

export function deleteSurveyStatus(id, responseType) {
  return api.delete(EP.DELETE_BASE_SURVEY_STATUS(id, responseType))
}
