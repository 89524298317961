import { PARTICIPANT } from '@store/actionTypes'
import {
  deleteSurveyStatus,
  downloadParticipantConsentWorksheet,
  editSurveyStatus,
  fetchAllParticipant,
  fetchAllParticipantConsents,
  fetchOneParticipant,
  updateParticipantById,
  uploadConsent,
  uploadCorrespondenceHistory,
  uploadDistribution,
} from '@api/participant'

import { createComment, deleteAComment, editComment } from '@api/comment'
import { createAction, editAction, editActionMarkComplete } from '@api/action'
import { createSchoolDetail } from '@api/school'
import { PARTICIPANT_MSGS } from '@constants/strings'
import {
  createContact,
  createContactDetail,
  createParticipantContactDetail,
  createPrimaryContact,
  createPrimaryContactDetail,
  editContact,
  makeOtherContactPrimary,
} from '@api/contacts'
import { message } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { getParentConsents } from '@actions/parentActions'
import { getMPOneParent } from '@actions/parentMPOneActions'
import { getMPOneParticipant } from '@actions/participantMPOneActions'
import { getRecallingSentences } from '@actions/recallingSentenceActions'

export const getParticipants = () => {
  return {
    type: PARTICIPANT.SET_PARTICIPANTS,
    async payload() {
      const resp = await fetchAllParticipant()
      return resp.data
    },
  }
}

export const getParticipant = (id) => {
  return {
    type: PARTICIPANT.SET_PARTICIPANTS_TO_DETAILS,
    async payload() {
      const resp = await fetchOneParticipant(id)
      return resp.data
    },
  }
}

export const getParticipantConsents = () => {
  return {
    type: PARTICIPANT.SET_PARTICIPANT_CONSENTS,
    async payload() {
      const resp = await fetchAllParticipantConsents()
      return resp.data
    },
  }
}
export const updateParticipant = (data) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT.UPDATE_PARTICIPANT,
    async payload() {
      const resp = await updateParticipantById(data.id, data)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', PARTICIPANT_MSGS.EDIT_DETAIL_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Save a Participant Comment to backend
 */

export const setComment = (data) => (dispatch) => {
  const payload = { note: data.note, date: data.date }
  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_COMMENT,
    async payload() {
      const resp = await createComment(data.userId, data.participantId, payload)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', PARTICIPANT_MSGS.ADD_COMMENT_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant Comment
 */
export const updateComment = (data) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT.UPDATE_PARTICIPANT_COMMENT,
    async payload() {
      const resp = await editComment(data.id, {
        note: data.note,
        date: data.date,
      })
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', PARTICIPANT_MSGS.EDIT_COMMENT_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Deleta a Participant Comment
 */
export const deleteComment = (id) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT.DELETE_PARTICIPANT_COMMENT,
    async payload() {
      const resp = await deleteAComment(id)
      const deleteCommentPayload = { data: resp.data, commentId: id }
      return deleteCommentPayload
    },
  })
  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Save a Participant Actions to backend
 */

export const setAction = (data) => (dispatch) => {
  const payload = {
    dueDate: data.dueDate,
    note: data.note,
  }
  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_ACTION,
    async payload() {
      const resp = await createAction(data.participantId, payload)
      return resp.data
    },
  })

  return response
    .then(async () => {
      await dispatch(getParticipant(data.participantId))
      dispatch({
        type: PARTICIPANT.UPDATE_NO_OF_ACTIONS_DUE,
      })
      message.success(get(response, 'message', PARTICIPANT_MSGS.ADD_ACTIONS_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant Action
 */
export const updateAction = (data) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT.UPDATE_PARTICIPANT_ACTION,
    async payload() {
      const resp = await editAction(data.id, {
        note: data.note,
        dueDate: data.dueDate,
        completionDate: data.completionDate,
      })
      return resp.data
    },
  })

  return response
    .then(async () => {
      await dispatch(getParticipant(data.participantId))
      dispatch({
        type: PARTICIPANT.UPDATE_NO_OF_ACTIONS_DUE,
      })
      message.success(get(response, 'message', PARTICIPANT_MSGS.EDIT_ACTIONS_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant Action and mark is complete
 */
export const updateActionComplete = (data) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT.UPDATE_PARTICIPANT_ACTION_MARK_COMPLETE,
    async payload() {
      const resp = await editActionMarkComplete(data.id, {
        resolutionNote: data.resolutionNote,
        completionDate: data.completionDate,
      })
      return resp.data
    },
  })

  return response
    .then(async () => {
      await dispatch(getParticipant(data.participantId))
      dispatch({
        type: PARTICIPANT.UPDATE_NO_OF_ACTIONS_DUE,
      })
      message.success(get(response, 'message', PARTICIPANT_MSGS.EDIT_ACTIONS_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Save a Participant Other Contacts to backend
 */

export const setContact = (data) => (dispatch) => {
  const payload = {
    firstName: data.firstName,
    lastName: data.lastName,
    relationship: data.relationship,
    currentContactDetails: {
      address: data.address,
      suburb: data.suburb,
      state: data.state,
      postalCode: data.postalCode,
      phoneNo: data.phoneNo,
      workPhoneNo: data.workPhoneNo,
      mobileNo: data.mobileNo,
      email: data.email,
    },
  }
  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT,
    async payload() {
      const resp = await createContact(data.type, data.participantId, payload)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', PARTICIPANT_MSGS.ADD_CONTACT_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Save a Participant Other Contacts Details to backend
 */

export const setContactDetail = (data) => (dispatch) => {
  const payload = {
    address: data.address,
    suburb: data.suburb,
    state: data.state,
    postalCode: data.postalCode,
    phoneNo: data.phoneNo,
    workPhoneNo: data.workPhoneNo,
    mobileNo: data.mobileNo,
    email: data.email,
    // firstName: data.firstName,
    // lastName: data.lastName,
    // relationship: data.relationship
  }
  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT_DETAIL,
    async payload() {
      const resp = await createContactDetail(data.id, payload)
      const contactDetailPayload = { data: resp.data, contactId: data.id }
      return contactDetailPayload
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', PARTICIPANT_MSGS.EDIT_CONTACT_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Save a Participant Primary Contact to backend
 */

export const setPrimaryContact = (data) => (dispatch) => {
  const payload = {
    firstName: data.firstName,
    lastName: data.lastName,
    relationship: data.relationship,
    currentContactDetails: {
      address: data.address,
      suburb: data.suburb,
      state: data.state,
      postalCode: data.postalCode,
      phoneNo: data.phoneNo,
      workPhoneNo: data.workPhoneNo,
      mobileNo: data.mobileNo,
      email: data.email,
    },
  }
  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_PRIMARY_CONTACT,
    async payload() {
      const resp = await createPrimaryContact(data.type, data.participantId, payload)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', PARTICIPANT_MSGS.ADD_CONTACT_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Save a Participant Primary Contact Details to backend
 */

export const setPrimaryContactDetail = (data) => (dispatch) => {
  const payload = {
    address: data.address,
    suburb: data.suburb,
    state: data.state,
    postalCode: data.postalCode,
    phoneNo: data.phoneNo,
    workPhoneNo: data.workPhoneNo,
    mobileNo: data.mobileNo,
    email: data.email,
  }
  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_PRIMARY_CONTACT_DETAIL,
    async payload() {
      const resp = await createPrimaryContactDetail(data.id, payload)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', PARTICIPANT_MSGS.EDIT_CONTACT_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update a Participant Primary Contact to backend
 */

export const setEditPrimaryContact = (data) => (dispatch) => {
  const payload = {
    firstName: data.firstName,
    lastName: data.lastName,
    relationship: data.relationship,
  }
  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_EDIT_PRIMARY_CONTACT,
    async payload() {
      const resp = await editContact(data.id, payload)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', PARTICIPANT_MSGS.EDIT_CONTACT_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update a Participant Other Contact to backend
 */

export const setEditOtherContact = (data) => (dispatch) => {
  const payload = {
    firstName: data.firstName,
    lastName: data.lastName,
    relationship: data.relationship,
  }
  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_EDIT_OTHER_CONTACT,
    async payload() {
      const resp = await editContact(data.id, payload)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', PARTICIPANT_MSGS.EDIT_CONTACT_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Save a Participant Contact Details to backend
 */

export const setParticipantContactDetail = (data) => (dispatch) => {
  const payload = {
    address: data.address,
    suburb: data.suburb,
    state: data.state,
    postalCode: data.postalCode,
    phoneNo: data.phoneNo,
    workPhoneNo: data.workPhoneNo,
    mobileNo: data.mobileNo,
    email: data.email,
  }
  const response = dispatch({
    type: PARTICIPANT.UPDATE_PARTICIPANT_CONTACT_DETAIL,
    async payload() {
      const resp = await createParticipantContactDetail(data.id, payload)
      return resp.data
    },
  })

  return response
    .then(() => {
      message.success(get(response, 'message', PARTICIPANT_MSGS.ADD_CONTACT_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Make a Participant Other Contact to Primary
 */

export const updateContactAccess = (data) => (dispatch) => {
  const payload = {
    contactId: data.id,
    isPrimary: true,
  }
  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT_PRIMARY,
    async payload() {
      // Its a patch request response null
      await makeOtherContactPrimary(data.participantId, payload)
      const participantOtherContact = { data: data }
      return participantOtherContact.data
    },
  })

  return response
    .then(() => {
      message.success(
        get(response, 'message', PARTICIPANT_MSGS.SET_OTHER_CONTACT_PRIMARY_MSG)
      )
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Save a Participant School Details to backend
 */

export const setSchoolDetail = (data, dest) => (dispatch) => {
  const payload = {
    suburb: data.suburb,
    state: data.state,
    status: data.status,
    yearLevel: data.yearLevel,
    name: data.name,
  }
  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_SCHOOL_DETAIL,
    async payload() {
      const resp = await createSchoolDetail(data.participantId, payload)
      return resp.data
    },
  })

  return response
    .then(async () => {
      if (dest === 'participants-list') {
        dispatch(getParticipants())
      } else if (dest === 'participant-consents') {
        dispatch(getParticipantConsents())
      } else if (dest === 'parent-consents') {
        dispatch(getParentConsents())
      } else if (dest === 'parent-mp1-consents') {
        dispatch(getMPOneParent())
      } else if (dest === 'participant-mp1-consents') {
        dispatch(getMPOneParticipant())
      } else if (dest === 'recalling-sentence') {
        dispatch(getRecallingSentences())
      }
      message.success(get(response, 'message', PARTICIPANT_MSGS.EDIT_SCHOOL_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Save a Participant Details to backend
 */

export const updateParticipantDetail = (data, dest) => (dispatch) => {
  const payload = {
    firstName: data.firstName,
    lastName: data.lastName,
    dob: data.dob,
    gender: data.gender,
    genderDescription: data.genderDescription,
    parentRecruitmentStatus: data.parentRecruitmentStatus,
    participantRecruitmentStatus: data.participantRecruitmentStatus,
  }
  const response = dispatch({
    type: PARTICIPANT.UPDATE_PARTICIPANT_DETAIL,
    async payload() {
      const resp = await updateParticipantById(data.participantId, payload)
      return resp.data
    },
  })

  return response
    .then(async () => {
      if (dest === 'participants-list') {
        dispatch(getParticipants())
      } else if (dest === 'participant-consents') {
        dispatch(getParticipantConsents())
      } else if (dest === 'parent-consents') {
        dispatch(getParentConsents())
      } else if (dest === 'parent-mp1-consents') {
        dispatch(getMPOneParent())
      } else if (dest === 'participant-mp1-consents') {
        dispatch(getMPOneParticipant())
      } else if (dest === 'recalling-sentence') {
        dispatch(getRecallingSentences())
      }
      message.success(get(response, 'message', PARTICIPANT_MSGS.EDIT_DETAIL_MSG))
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant uploaded distribution History file
 */
export const setParticipantDistribution = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT.SET_UPLOAD_PARTICIPANT_DISTRIBUTION_HISTORY,
    async payload() {
      const resp = await uploadDistribution('participantConsent', formData)
      return resp.data
    },
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant uploaded Consent file
 */
export const setParticipantConsent = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT.SET_UPLOAD_PARTICIPANT_CONSENT,
    async payload() {
      const resp = await uploadConsent('participantConsent', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getParticipantConsents())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant uploaded Correspondence History file
 */

export const setParticipantCorrespondenceHistory = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT.SET_UPLOAD_PARTICIPANT_CORR_HISTORY,
    async payload() {
      const resp = await uploadCorrespondenceHistory('participantConsent', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getParticipantConsents())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant Survey Contact Status i.e: EmailSent, SMSSent, callMade
 */
export const updateParticipantContactStatus = (data) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT.UPDATE_PARTICIPANT_SURVEY_CONTACT_STATUS,
    async payload() {
      const resp = await editSurveyStatus(data.id, {
        emailDate: data.emailDate !== null ? moment(data.emailDate).format() : null,
        smsDate: data.smsDate !== null ? moment(data.smsDate).format() : null,
        callDate: data.callDate !== null ? moment(data.callDate).format() : null,
        invitationDate:
          data.invitationDate !== null ? moment(data.invitationDate).format() : null,
        endDate: data.endDate !== null ? moment(data.endDate).format() : null,
      })
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getParticipantConsents())
      message.success(
        get(
          response,
          'message',
          PARTICIPANT_MSGS.EDIT_PARTICIPANT_CONTACT_STATUS_SUCCESS_MSG
        )
      )
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Delete Participant Consent Survey i.e:  responseId, completionDate, etc
 */
export const deleteParticipantConsentSurvey = (id) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT.DELETE_PARTICIPANT_CONSENT_SURVEY,
    async payload() {
      const resp = await deleteSurveyStatus(id, 'participantConsent')
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getParticipantConsents())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get Participant Consent Worksheet Download
 */

export const getParticipantConsentWorkSheetDownload = (data) => (dispatch) => {
  let payload
  data.length > 0
    ? (payload = {
        participantUuids: data,
      })
    : (payload = {})

  const response = dispatch({
    type: PARTICIPANT.SET_PARTICIPANT_DOWNLOAD_FILE,
    async payload() {
      const resp = await downloadParticipantConsentWorksheet(
        'participantConsent',
        payload
      )
      const fileName = resp.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]/g, '')
      const fileDownload = require('js-file-download')
      fileDownload(resp.data, fileName)
      return resp.data
    },
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
