import { Form, Input, Button, Switch } from 'antd'
import React from 'react'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}

function UserForm(props) {
  const { onUserSubmit } = props

  const handleFinish = (values) => {
    onUserSubmit(values)
  }

  /* eslint-disable */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  /* eslint-enable */

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={handleFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name={['name']}
        label="User Name"
        rules={[
          {
            required: true,
            message: 'Please input user name!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['email']}
        label="Email"
        rules={[
          {
            type: 'email',
            required: true,
            message: 'Please input user email!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          { required: true, message: 'Please input user password!' },
          { min: 8, message: 'Password must be minimum 8 characters.' },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm user password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                'The two passwords that you entered do not match!'
              )
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name={['notes']}
        label="Notes"
        rules={[
          {
            message: 'Please input user notes!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Disable" name={['disabled']}>
        <Switch defaultChecked={false} />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <ButtonContainer>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </ButtonContainer>
      </Form.Item>
    </Form>
  )
}
export default UserForm
