const BASE = '/api'

export default {
  //User
  USER_LOGIN: () => `${BASE}/login`,
  USER_LOGOUT: () => `${BASE}/logout`,
  USER_REGISTER: () => `${BASE}/users/`,
  ALL_USERS: () => `${BASE}/users/`,
  GET_DOWNLOAD_BULK_WORKSHEET: () => `${BASE}/downloads/dump`,
  SET_USER_DETAILS: (userId) => `${BASE}/users/${userId}`,
  SET_OTHER_USER_PASSWORD: (userId) => `${BASE}/users/${userId}/password-reset`,

  //DASHBOARD
  ALL_DASHBOARD_STATS: () => `${BASE}/stats/dashboard`,

  // PARTICIPANTS
  PARTICIPANTS_BY_ID: (id) => `${BASE}/participants/${id}`,
  ALL_PARTICIPANTS: () => `${BASE}/participants`,
  ALL_PARTICIPANT_CONSENTS: () => `${BASE}/participants/consents`,
  PARTICIPANT_ADD_COMM_LOG: (userId, participantId) =>
    `${BASE}/communications/participants/${participantId}/users/${userId}`,
  PARTICIPANT_UPDATE_COMM_LOG: (commId) => `${BASE}/communications/${commId}`,
  PARTICIPANT_ADD_COMMENT: (userId, participantId) =>
    `${BASE}/comments/participants/${participantId}/users/${userId}`,
  PARTICIPANT_UPDATE_COMMENT: (commentId) => `${BASE}/comments/${commentId}`,
  PARTICIPANT_DELETE_COMMENT: (commentId) => `${BASE}/comments/${commentId}`,
  PARTICIPANT_ADD_ACTION: (participantId) =>
    `${BASE}/actions/participants/${participantId}`,
  PARTICIPANT_UPDATE_ACTION: (actionId) => `${BASE}/actions/${actionId}`,
  PARTICIPANT_UPDATE_ACTION_MARK_COMPLETE: (actionId) =>
    `${BASE}/actions/${actionId}/resolution`,
  PARTICIPANT_ADD_CONTACT: (type, participantId) =>
    `${BASE}/contacts/participants/${participantId}?type=${type}`,
  PARTICIPANT_ADD_CONTACT_DETAIL: (id) => `${BASE}/contact-details/contacts/${id}`,
  PARTICIPANT_EDIT_CONTACT_DETAIL: (id) => `${BASE}/contacts/${id}`,
  PARTICIPANT_ADD_PARTICIPANT_CONTACT_DETAIL: (id) =>
    `${BASE}/contact-details/participants/${id}`,
  PARTICIPANT_MAKE_OTHER_CONTACT_PRIMARY: (participantId) =>
    `${BASE}/contacts/participants/${participantId}`,
  PARTICIPANT_ADD_SCHOOL: (participantId) =>
    `${BASE}/schools/participants/${participantId}`,
  PARTICIPANT_UPDATE_DETAIL: (participantId) =>
    `${BASE}/participants/${participantId}`,
  // PARENT CONSENTS
  ALL_PARENT_CONSENTS: () => `${BASE}/parents/consents`,
  // DISTRIBUTION HISTORY (Parent, Participant, Recalling Sentence)
  UPLOAD_DISTRIBUTION_HISTORY: (type) =>
    `${BASE}/uploads/distributions?type=${type}`,
  // UPLOAD CONSENT RESPONSES (Parent, Participant, Recalling Sentence)
  UPLOAD_SURVEY_RESPONSE: (type) => `${BASE}/uploads/survey-response?type=${type}`,
  // UPLOAD CORR HISTORY (Parent, Participant, Recalling Sentence)
  UPLOAD_CORRESPONDENCE_HISTORY: (type) =>
    `${BASE}/uploads/correspondence-history?type=${type}`,
  // DOWNLOAD WORKSHEET (Parent, Participant, Recalling Sentence)
  DOWNLOAD_WORK_SHEET: (type) => `${BASE}/downloads/work-sheet/${type}`,
  UPDATE_BASE_SURVEY_STATUS: (surveyId) => `${BASE}/base-survey/${surveyId}`,
  DELETE_BASE_SURVEY_STATUS: (surveyId, responseType) =>
    `${BASE}/base-survey/${surveyId}/responses/${responseType}`,
  ALL_RECALLING_SENTENCES: () => `${BASE}/recalling-sentences`,

  // PARENT MEASURE PACKAGE 1
  ALL_PARENT_MP_ONE: () => `${BASE}/parents/mp1`,

  // PARTICIPANT MEASURE PACKAGE 1
  ALL_PARTICIPANT_MP_ONE: () => `${BASE}/participants/mp1`,

  // PARENT MEASURE PACKAGE 2
  ALL_PARENT_MP_TWO: () => `${BASE}/parents/mp2`,

  // PARTICIPANT MEASURE PACKAGE 1
  ALL_PARTICIPANT_MP_TWO: () => `${BASE}/participants/mp2`,

  // PARTICIPANT MEASURE PACKAGE 3
  ALL_PARTICIPANT_MP_THREE: () => `${BASE}/participants/mp3`,

  UPDATE_PARTICIPANT_MP_THREE_VARIABLES: (participantId) =>
    `${BASE}/participants/mp3/${participantId}`,
}
