import { Form, Input, Button, Select, Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 10,
  },
}

const { Option } = Select

function ContactDetailForm(props) {
  const { onContactDetailSubmit, data } = props
  const [form] = Form.useForm()
  const [, forceUpdate] = useState() // To disable submit button at the beginning
  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleFinish = (values) => {
    onContactDetailSubmit({ ...values, id: data.id })
  }
  /* eslint-disable */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  /* eslint-enable */

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="61">+61</Option>
      </Select>
    </Form.Item>
  )

  return (
    <Form
      form={form}
      name="nest-messages"
      // className="ant-advanced-search-form"
      validateMessages={validateMessages}
      onFinish={handleFinish}
      initialValues={{
        id: data?.id,
        state: data?.currentContactDetails?.state,
        suburb: data?.currentContactDetails?.suburb,
        postalCode: data?.currentContactDetails?.postalCode,
        address: data?.currentContactDetails?.address,
        email: data?.currentContactDetails?.email,
        phoneNo: data?.currentContactDetails?.phoneNo,
        workPhoneNo: data?.currentContactDetails?.workPhoneNo,
        mobileNo: data?.currentContactDetails?.mobileNo,
      }}
    >
      <Row gutter={24}>
        <Col span={12} key={1}>
          <Form.Item
            label="State"
            name={['state']}
            rules={[
              {
                // required: true,
              },
            ]}
          >
            <Select placeholder="Select State">
              <Select.Option value="ACT">ACT</Select.Option>
              <Select.Option value="QLD">QLD</Select.Option>
              <Select.Option value="NSW">NSW</Select.Option>
              <Select.Option value="NT">NT</Select.Option>
              <Select.Option value="SA">SA</Select.Option>
              <Select.Option value="TAS">TAS</Select.Option>
              <Select.Option value="VIC">VIC</Select.Option>
              <Select.Option value="WA">WA</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} key={2}>
          <Form.Item
            name={['suburb']}
            label="Suburb"
            rules={[
              {
                // required: true,
                initialValue: data?.currentContactDetails?.suburb,
              },
            ]}
          >
            <Input placeholder="Please input Suburb" />
          </Form.Item>
        </Col>
        <Col span={12} key={3}>
          <Form.Item
            name={['postalCode']}
            label="Post Code"
            rules={[
              {
                // required: true,
                message: 'Please input Post Code',
              },
            ]}
          >
            <Input maxLength={4} placeholder="Please input Post Code" />
          </Form.Item>
        </Col>
        <Col span={12} key={4}>
          <Form.Item
            name={['address']}
            label="Address"
            rules={[
              {
                // required: true,
              },
            ]}
          >
            <Input placeholder="Please input Address" />
          </Form.Item>
        </Col>
        <Col span={12} key={5}>
          <Form.Item
            name={['email']}
            label="Email"
            rules={[
              {
                // required: true,
                type: 'email',
              },
            ]}
          >
            <Input placeholder="Please input Email" />
          </Form.Item>
        </Col>
        <Col span={12} key={6}>
          <Form.Item
            name={['phoneNo']}
            label="Phone #"
            rules={[
              {
                // required: true,
                message: 'Please input Phone #',
              },
            ]}
          >
            <Input
              maxLength={12}
              addonBefore={prefixSelector}
              placeholder="XXXX-XXX-XXX"
            />
          </Form.Item>
        </Col>
        <Col span={12} key={7}>
          <Form.Item
            name={['workPhoneNo']}
            label="Work Phone #"
            rules={[
              {
                // required: true,
                message: 'Please input your Work Phone #',
              },
            ]}
          >
            <Input
              maxLength={12}
              addonBefore={prefixSelector}
              placeholder="XXXX-XXX-XXX"
            />
          </Form.Item>
        </Col>
        <Col span={12} key={8}>
          <Form.Item
            name={['mobileNo']}
            label="Mobile #"
            rules={[
              {
                // required: true,
                message: 'Please input mobile #!',
              },
            ]}
          >
            <Input
              maxLength={12}
              addonBefore={prefixSelector}
              placeholder="XXXX-XXX-XXX"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} shouldUpdate>
        {() => (
          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                (!form.isFieldTouched('postalCode') &&
                  !form.isFieldTouched('state') &&
                  !form.isFieldTouched('suburb') &&
                  !form.isFieldTouched('address') &&
                  !form.isFieldTouched('email') &&
                  !form.isFieldTouched('phoneNo') &&
                  !form.isFieldTouched('workPhoneNo') &&
                  !form.isFieldTouched('mobileNo')) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Submit
            </Button>
          </ButtonContainer>
        )}
      </Form.Item>
    </Form>
  )
}
export default ContactDetailForm
