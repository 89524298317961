import React, { useEffect } from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { AppRoutes, LoginRoutes } from '@src/routes'
import AppSideBar from '@components/appSidebar'
import style from '@src/style.module.less'
import Header from '@containers/header'
import MainContentLayout from '@components/layout/mainContent'
import { restoreState } from '@actions/generalActions'
import { useLocation } from 'react-router-dom'
const { Content } = Layout

function LayoutContainer(props) {
  const { user, restoreState } = props
  useEffect(() => {
    restoreState()
  }, [restoreState])

  const defaultRoute = useLocation().pathname

  const renderContent = () => {
    // if (user && user.accessToken) {
    return (
      <Layout hasSider>
        <AppSideBar />
        <Layout>
          <Content className={style.appContent}>
            <MainContentLayout>
              <AppRoutes defaultRoute={defaultRoute} />
            </MainContentLayout>
          </Content>
        </Layout>
      </Layout>
    )
  }

  const renderLogin = () => {
    return (
      <Content className={style.appContent}>
        <LoginRoutes />
      </Content>
    )
  }
  return (
    <Layout className={style.appLayout}>
      <Header user={user} />
      {user && user.accessToken ? renderContent() : renderLogin()}
    </Layout>
  )
}

const mapStateToProps = ({ user, isLogged }) => {
  return {
    user: user.user,
    isLogged: isLogged,
  }
}

const mapDispatchToProps = {
  restoreState,
}

Layout.propTypes = {
  user: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)
