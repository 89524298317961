import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import Loading from '@components/common/loading/scale'
import styled from 'styled-components'

const LoadingDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`

export default function ModalComponent(props) {
  const {
    handleVisibility,
    visible,
    width,
    minHeight,
    title,
    children,
    loading,
    ...restProps
  } = props
  return (
    <Modal
      width={width || 700}
      title={title}
      visible={visible}
      onCancel={handleVisibility}
      footer={null}
      bodyStyle={{ minHeight: { minHeight } }}
      {...restProps}
    >
      {loading ? (
        <LoadingDiv>
          <Loading loading={loading} />
        </LoadingDiv>
      ) : (
        children
      )}
    </Modal>
  )
}

ModalComponent.propTypes = {
  handleVisibility: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  width: PropTypes.number,
  title: PropTypes.string.isRequired,
}
