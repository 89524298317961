const KEY = 'elvs_user'

/**
 * Get a value stored in the localStorage.
 * @param {?string} key - Leave blank to retrieve everything.
 * @returns {*}
 */
export function getStorage(key = undefined) {
  try {
    let data = window.localStorage.getItem(KEY)
    data = JSON.parse(data)
    if (key === undefined) {
      return data
    } else {
      return data[key]
    }
  } catch (ignored) {
    return {}
  }
}

/**
 * Save a value into the localStorage.
 * (!) must be serializable!
 * @param {string} key
 * @param {*} value
 */
export function setStorage(key, value) {
  try {
    const data = JSON.stringify(value)
    window.localStorage.setItem(key, data)
  } catch (ignored) {
    window.localStorage.removeItem(key)
  }
}
