import 'primeicons/primeicons.css'
import 'primereact/resources/themes/nova-light/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import '@src/index.css'
import React, { useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import ParticipantFilterSaveContainer from '@containers/filters/filterSave'
import ParticipantFilterClearContainer from '@containers/filters/filterClear'
import classNames from 'classnames'
import { ProfileOutlined, AlertOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Button, Divider, message, Select, Tooltip } from 'antd'
const { Option } = Select

export default function ParticipantContainer(props) {
  const { participants, getParticipant, handleParticipantDetailsDisplay } = props

  // All Statuses for dropdown filters
  const gender = ['Female', 'Male', 'Other']
  const combineRecruitStatus = ['Inactive', 'Active']
  const parentRecruitStatus = [
    'NOT_ELIGIBLE',
    'ELIGIBLE',
    'UNCONTACTABLE',
    'APPROACHED',
    'DID_NOT_RESPOND',
    'DECLINED',
    'RECRUITED',
    'WITHDREW',
    'RECRUITED_THEN_UNCONTACTABLE',
  ]
  const participantRecruitStatus = [
    'NOT_ELIGIBLE',
    'ELIGIBLE',
    'UNCONTACTABLE',
    'APPROACHED',
    'DID_NOT_RESPOND',
    'DECLINED_BY_PARENT',
    'DECLINED_BY_TEEN',
    'PARENT_CONSENT_UNKNOWN',
    'RECRUITED',
    'WITHDREW_BY_PARENT',
    'WITHDREW_BY_TEEN',
    'RECRUITED_THEN_UNCONTACTABLE',
  ]
  ///

  const actionRequired = ['Due Actions', 'All']
  const dt = useRef(null)
  //eslint-disable-next-line
  const [selectedDataForDownload, setSelectedDataForDownload] = useState([])
  const [globalFilter, setGlobalFilter] = useState(null)
  const [dateFilter, setDateFilter] = useState(null)
  const [selectedAction, setSelectedAction] = useState(null)
  const [selectedGender, setSelectedGender] = useState(null)
  const [selectedCombineRecruitStatus, setCombineRecruitStatus] = useState(null)
  const [selectedParentRecruitStatus, setParentRecruitStatus] = useState(null)
  const [selectedParticipantRecruitStatus, setParticipantRecruitStatus] =
    useState(null)
  //eslint-disable-next-line
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [savedFilterData, setSavedDataFilter] = useState([])
  const [filters, setFilters] = useState(null)

  useEffect(() => {
    reInitialiseSelectData()
  }, [])

  // Filter Save, remove one and remove all
  const onSaveFilter = (filterName) => {
    const savedFilter = JSON.parse(localStorage.getItem('participantListFilter'))
    let arr = []
    if (filters != null) {
      if (savedFilter != null && savedFilter.length > 0) {
        // Check the filters is already in the local Storage
        for (var i = 0; i < savedFilter.length; i++) {
          if (JSON.stringify(savedFilter[i]) === JSON.stringify(filters)) {
            break
          } else {
            let final_obj = { filterName: filterName, filters: filters }
            savedFilter.push(final_obj)
            localStorage.setItem(
              'participantListFilter',
              JSON.stringify(savedFilter)
            )
            reInitialiseSelectData()
            message.success('The choosen filter has been saved successfully!')
            break
          }
        }
      } else {
        // For the first time when no filter is set before
        let final_obj = { filterName: filterName, filters: filters }
        arr.push(final_obj)
        localStorage.setItem('participantListFilter', JSON.stringify(arr))
        reInitialiseSelectData()
        message.success('The choosen filter has been saved successfully!')
      }
    } else {
      message.warning('Please apply the filter on the table then save filter!')
    }
  }

  const onFilter = (e) => {
    setFilters(e.filters)
  }
  const reInitialiseSelectData = () => {
    let data = []
    const savedFilter = JSON.parse(localStorage.getItem('participantListFilter'))
    if (savedFilter != null) {
      for (var i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
    setSavedDataFilter(data)
  }

  const onApplyFilter = (filter) => {
    if (filter !== null) {
      let selectedKeys = Object.entries(JSON.parse(filter))
      for (var i = 0; i < selectedKeys.length; i++) {
        if (selectedKeys[i][0] === 'gender') {
          setSelectedGender(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'recruitmentStatus') {
          setCombineRecruitStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'parentRecruitmentStatus') {
          setParentRecruitStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'participantRecruitmentStatus') {
          setParticipantRecruitStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'noOfActionsDue') {
          setSelectedAction(selectedKeys[i][1].value)
        }
      }
      setFilters(JSON.parse(filter))
      message.success('The choosen filter has been applied successfully!')
    } else {
      setFilters(null)
    }
  }
  const onClearFilterAll = () => {
    localStorage.removeItem('participantListFilter')
    message.success('All filters have been removed successfully!')
    reInitialiseSelectData()
  }

  const onRemoveFilterOne = (choosenFilter) => {
    const allSavedFilter = JSON.parse(localStorage.getItem('participantListFilter'))
    for (var i = 0; i < allSavedFilter.length; i++) {
      if (JSON.stringify(allSavedFilter[i].filters) === choosenFilter) {
        allSavedFilter.splice(i, 1)
        localStorage.removeItem('participantListFilter')
        localStorage.setItem('participantListFilter', JSON.stringify(allSavedFilter))
        message.success('The choosen filter has been removed successfully!')
        break
      }
    }
    reInitialiseSelectData()
  }

  const onChangeFilter = (value) => {
    if (value !== undefined) {
      setSelectedFilter(value)
      onApplyFilter(value)
    } else {
      setSelectedFilter(null)
      clearAllFilterValues()
      onApplyFilter(null)
    }
  }

  const clearAllFilterValues = () => {
    setDateFilter(null)
    setSelectedAction(null)
    setSelectedGender(null)
    setCombineRecruitStatus(null)
    setParentRecruitStatus(null)
    setParticipantRecruitStatus(null)
    setGlobalFilter(null)
  }

  const clearAllFilterTable = () => {
    setSelectedFilter(null)
    clearAllFilterValues()
    onApplyFilter(null)
  }

  const onDataTableValueChange = (filteredData) => {
    setSelectedDataForDownload(filteredData)
  }

  const renderHeader = () => {
    return (
      <div>
        Participants List
        <div style={{ display: 'flex' }}>
          <span>
            <p style={{ width: '1550px' }}></p>{' '}
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ width: '25px' }}></p>
          <div>
            <ParticipantFilterSaveContainer
              name="participant-list"
              onSaveFilter={onSaveFilter}
              filters={filters}
            />
          </div>
          <p style={{ width: '25px' }}></p>
          <div>
            <Select
              showSearch
              style={{ width: 220 }}
              placeholder="Choose a Filter to Apply"
              optionFilterProp="savedFilterData"
              onChange={onChangeFilter}
              allowClear={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {savedFilterData.map(function (option, i) {
                return (
                  <Option key={i} value={option.value}>
                    {option.label}
                  </Option>
                )
              })}
            </Select>
          </div>
          <p style={{ width: '25px' }}></p>
          <div>
            <ParticipantFilterClearContainer
              name="participant-list"
              onRemoveFilterOne={onRemoveFilterOne}
              onClearFilterAll={onClearFilterAll}
            />
          </div>
          <p style={{ width: '25px' }}></p>
          <div>
            <Button
              onClick={clearAllFilterTable}
              style={{
                backgroundColor: '#008385',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Clear Filters
            </Button>
          </div>
          <p style={{ width: '550px' }}></p>
          <div className="p-datatable-globalfilter-container">
            <InputText
              type="search"
              onInput={(e) => {
                setGlobalFilter(e.target.value)
              }}
              placeholder="Global Search"
            />
          </div>
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <span>
            <p style={{ width: '1550px' }}></p>
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ width: '25px' }}></p>
          {/*<div>*/}
          {/* Hide it from the participant List, will decide later to show with a massive csv to download*/}
          {/*{participants && (*/}
          {/*  <DownloadParticipants*/}
          {/*    data={selectedDataForDownload}*/}
          {/*    rawData={participants}*/}
          {/*    buttonLabel="Download for Mail Merge"*/}
          {/*    getDataDownload={getDataForMailMergeDownload}*/}
          {/*    componentId="participant-consent"*/}
          {/*    loading={loading}*/}
          {/*  />*/}
          {/*)}*/}
          {/*</div>*/}
          {/*<p style={{ width: '25px' }}></p>*/}
          <p style={{ width: '800px' }}></p>
        </div>
      </div>
    )
  }

  const genderBodyTemplate = (rowData) => {
    return (
      <span className={classNames('participant-badge', 'gender-' + rowData.gender)}>
        {rowData.gender}
      </span>
    )
  }

  const statusBodyTemplate = (rowData) => {
    let recruitementStatus =
      rowData.recruitmentStatus !== null ? rowData.recruitmentStatus : '--'
    return (
      <span
        className={classNames(
          'participant-badge',
          'recruitmentStatus-' + recruitementStatus
        )}
      >
        {recruitementStatus}
      </span>
    )
  }
  const dateBodyTemplate = (rowData) => {
    let dob =
      rowData.dob !== null && rowData.dob !== undefined
        ? moment(rowData.dob).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + dob)}>{dob}</span>
    )
  }
  const actionDueBodyTemplate = (rowData) => {
    const hasActions = rowData.noOfActionsDue
    const latestDueDate = moment(rowData.latestDueDate).format('DD-MM-YYYY')
    let alarm
    if (hasActions > 0) {
      alarm =
        rowData.latestDueDate !== null && rowData.latestDueDate !== undefined ? (
          <span>
            {hasActions} <AlertOutlined style={{ color: 'red' }} /> --{' '}
            {latestDueDate}
          </span>
        ) : (
          <span>
            {hasActions} <AlertOutlined style={{ color: 'red' }} />
          </span>
        )
    } else {
      alarm = <AlertOutlined style={{ color: 'green' }} />
    }
    return (
      <span
        className={classNames(
          'participant-badge',
          'status-' + rowData.noOfActionsDue
        )}
      >
        <Tooltip title="Actions required">
          <span> {alarm}</span>
        </Tooltip>
      </span>
    )
  }

  const operationBodyTemplate = (rowData) => {
    return (
      <span>
        <Tooltip title="Participant details">
          <span>
            <ProfileOutlined
              onClick={() => {
                getParticipant(rowData.id)
                handleParticipantDetailsDisplay()
              }}
            />
          </span>
          <Divider type="vertical" />
        </Tooltip>
      </span>
    )
  }

  const renderDateFilter = () => {
    return (
      <Calendar
        value={dateFilter}
        onChange={onDateFilterChange}
        placeholder="Search by DOB"
        dateFormat="dd-mm-yy"
        monthNavigator={true}
        yearNavigator={true}
        yearRange="2002:2020"
        className="p-column-filter"
      />
    )
  }
  const onDateFilterChange = (event) => {
    if (event.value !== null) {
      dt.current.filter(formatDate(event.value), 'dob', 'contains')
    } else dt.current.filter(null, 'dob', 'contains')

    setDateFilter(moment(event.value).format('DD-MM-YYYY'))
  }
  const filterDate = (value, filter) => {
    if (
      filter === undefined ||
      filter === null ||
      (typeof filter === 'string' && filter.trim() === '')
    ) {
      return true
    }

    if (value === undefined || value === null) {
      return false
    }
    return value === formatDate(filter)
  }

  const formatDate = (date) => {
    let month = date.getMonth() + 1
    let day = date.getDate()

    if (month < 10) {
      month = '0' + month
    }

    if (day < 10) {
      day = '0' + day
    }
    // from the DB the date Format is UTC i.e: YYYY-MM-DDTHH:MM:00
    return date.getFullYear() + '-' + month + '-' + day
  }

  // Gender Related methods
  const renderGenderFilter = () => {
    return (
      <Dropdown
        value={selectedGender}
        options={gender}
        onChange={onGenderFilterChange}
        itemTemplate={genderItemTemplate}
        showClear
        placeholder="Search By Gender"
        className="p-column-filter"
      />
    )
  }
  const genderItemTemplate = (option) => {
    return (
      <span className={classNames('participant-badge', 'gender-' + option)}>
        {option}
      </span>
    )
  }
  const onGenderFilterChange = (event) => {
    dt.current.filter(event.value, 'gender', 'equals')
    setSelectedGender(event.value)
  }
  // End Gender related methods

  // Action related Methods
  const renderActionsFilter = () => {
    return (
      <Dropdown
        value={selectedAction}
        options={actionRequired}
        onChange={onActionFilterChange}
        itemTemplate={actionItemTemplate}
        showClear
        placeholder="Search Due Actions"
        className="p-column-filter"
      />
    )
  }

  const actionItemTemplate = (option) => {
    return (
      <span className={classNames('participant-badge', 'p-status-' + option)}>
        {option}
      </span>
    )
  }

  const onActionFilterChange = (event) => {
    if (event.value === 'Due Actions') {
      dt.current.filter(1, 'noOfActionsDue', 'gte')
    } else {
      dt.current.filter(0, 'noOfActionsDue', 'gte')
    }
    setSelectedAction(event.value)
  }
  ///
  /// Status
  const renderStatusFilter = () => {
    return (
      <Dropdown
        value={selectedCombineRecruitStatus}
        options={combineRecruitStatus}
        onChange={onStatusFilterChange}
        itemTemplate={statusItemTemplate}
        showClear
        placeholder="Search Status"
        className="p-column-filter"
      />
    )
  }

  const statusItemTemplate = (option) => {
    return (
      <span
        className={classNames('participant-badge', 'recruitmentStatus-' + option)}
      >
        {option}
      </span>
    )
  }
  const onStatusFilterChange = (event) => {
    dt.current.filter(event.value, 'recruitmentStatus', 'equals')
    setCombineRecruitStatus(event.value)
  }
  /// End Combine Recruitment Status

  // Start Parent Recruitment Status
  const statusBodyParentRecruitTemplate = (rowData) => {
    let parentRecruitmentStatus =
      rowData.parentRecruitmentStatus !== null
        ? rowData.parentRecruitmentStatus
        : '--'
    return (
      <span
        className={classNames(
          'participant-badge',
          'ParentRecruitmentStatus-' + parentRecruitmentStatus
        )}
      >
        {parentRecruitmentStatus}
      </span>
    )
  }

  const renderStatusParentRecruitFilter = () => {
    return (
      <Dropdown
        value={selectedParentRecruitStatus}
        options={parentRecruitStatus}
        onChange={onParentRecruitStatusFilterChange}
        itemTemplate={parentRecruitStatusItemTemplate}
        showClear
        placeholder="Search Status"
        className="p-column-filter"
      />
    )
  }

  const parentRecruitStatusItemTemplate = (option) => {
    return (
      <span
        className={classNames(
          'participant-badge',
          'ParentRecruitmentStatus-' + option
        )}
      >
        {option}
      </span>
    )
  }
  const onParentRecruitStatusFilterChange = (event) => {
    dt.current.filter(event.value, 'parentRecruitmentStatus', 'equals')
    setParentRecruitStatus(event.value)
  }
  /// end Parent Recruitment Status

  // Start Parent Recruitment Status
  const statusBodyParticipantRecruitTemplate = (rowData) => {
    let participantRecruitmentStatus =
      rowData.participantRecruitmentStatus !== null
        ? rowData.participantRecruitmentStatus
        : '--'
    return (
      <span
        className={classNames(
          'participant-badge',
          'ParticipantRecruitmentStatus-' + participantRecruitmentStatus
        )}
      >
        {participantRecruitmentStatus}
      </span>
    )
  }
  const renderStatusParticipantRecruitFilter = () => {
    return (
      <Dropdown
        value={selectedParticipantRecruitStatus}
        options={participantRecruitStatus}
        onChange={onParticipantRecruitStatusFilterChange}
        itemTemplate={participantRecruitStatusItemTemplate}
        showClear
        placeholder="Search Status"
        className="p-column-filter"
      />
    )
  }

  const participantRecruitStatusItemTemplate = (option) => {
    return (
      <span
        className={classNames(
          'participant-badge',
          'ParticipantRecruitmentStatus-' + option
        )}
      >
        {option}
      </span>
    )
  }
  const onParticipantRecruitStatusFilterChange = (event) => {
    dt.current.filter(event.value, 'participantRecruitmentStatus', 'equals')
    setParticipantRecruitStatus(event.value)
  }
  /// end Participant Recruitment Status

  const onTableRowClick = (rowData) => {
    getParticipant(rowData.data.id)
    handleParticipantDetailsDisplay()
  }

  const header = renderHeader()
  const actionFilter = renderActionsFilter()
  const genderFilter = renderGenderFilter()
  const statusFilter = renderStatusFilter()
  const statusParentRecruitFilter = renderStatusParentRecruitFilter()
  const statusParticipantRecruitFilter = renderStatusParticipantRecruitFilter()
  const footer = renderFooter()

  // eslint-disable-next-line
  const check = () => {
    return participants?.length
  }
  return (
    <div className="datatable-doc-demo" style={{ width: '2300px' }}>
      <DataTable
        ref={dt}
        value={participants}
        onValueChange={onDataTableValueChange}
        header={header}
        footer={footer}
        filters={filters}
        onFilter={onFilter}
        responsive
        className="p-datatable-customers"
        dataKey="id"
        rowHover={true}
        globalFilter={globalFilter}
        onRowClick={onTableRowClick}
        paginator
        rows={10}
        emptyMessage="No participants found"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 100, 200, 300, participants?.length]}
      >
        <Column
          field="participantId"
          header="Participant ID"
          headerStyle={{ width: '120px' }}
          sortable
          filter
          filterPlaceholder="Search by Participant ID"
        />
        <Column
          field="latestDueDate"
          header="Outstanding Actions"
          headerStyle={{ width: '150px' }}
          body={actionDueBodyTemplate}
          sortable
          filter
          filterElement={actionFilter}
          filterPlaceholder="Search by Due Actions"
        />
        <Column field="noOfActionsDue" style={{ display: 'none' }} />
        <Column
          field="firstName"
          header="First Name"
          headerStyle={{ width: '120px' }}
          sortable
          filter
          filterPlaceholder="Search by First Name"
        />
        <Column
          field="lastName"
          header="Last Name"
          headerStyle={{ width: '120px' }}
          sortable
          filter
          filterPlaceholder="Search by Last Name"
        />
        <Column
          field="email"
          header="Participant Email"
          headerStyle={{ width: '210px' }}
          sortable
          filter
          filterPlaceholder="Search by Email"
        />
        <Column
          field="mobileNo"
          header="Participant Mobile"
          headerStyle={{ width: '120px' }}
          sortable
          filter
          filterPlaceholder="Search by Mobile"
        />
        <Column
          field="gender"
          header="Gender"
          headerStyle={{ width: '100px' }}
          body={genderBodyTemplate}
          sortable
          filter
          filterElement={genderFilter}
        />
        <Column
          field="parentFirstName"
          header="Primary Contact First Name"
          headerStyle={{ width: '120px' }}
          sortable
          filter
          filterPlaceholder="Search by First Name"
        />
        <Column
          field="parentLastName"
          header="Primary Contact Last Name"
          headerStyle={{ width: '120px' }}
          sortable
          filter
          filterPlaceholder="Search by Last Name"
        />
        <Column
          field="parentEmail"
          header="Primary Contact Email"
          headerStyle={{ width: '210px' }}
          sortable
          filter
          filterPlaceholder="Search by Email"
        />
        <Column
          field="parentMobileNo"
          header="Primary Contact Mobile"
          headerStyle={{ width: '120px' }}
          sortable
          filter
          filterPlaceholder="Search by Mobile"
        />
        <Column
          field="recruitmentStatus"
          header="Status"
          headerStyle={{ width: '100px' }}
          body={statusBodyTemplate}
          sortable
          filter
          filterElement={statusFilter}
        />
        <Column
          field="parentRecruitmentStatus"
          header="Parent Recruitment Status"
          headerStyle={{ width: '180px' }}
          body={statusBodyParentRecruitTemplate}
          sortable
          filter
          filterElement={statusParentRecruitFilter}
        />
        <Column
          field="participantRecruitmentStatus"
          header="Teen Recruitment Status"
          headerStyle={{ width: '210px' }}
          body={statusBodyParticipantRecruitTemplate}
          sortable
          filter
          filterElement={statusParticipantRecruitFilter}
        />
        <Column
          field="dob"
          headerStyle={{ width: '120px' }}
          body={dateBodyTemplate}
          header="DoB"
          sortable
          filter
          filterMatchMode="custom"
          filterFunction={filterDate}
          filterElement={renderDateFilter()}
        />
        <Column
          body={operationBodyTemplate}
          headerStyle={{ width: '8em', textAlign: 'center' }}
          header="Operations"
          bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
        />
      </DataTable>
    </div>
  )
}
