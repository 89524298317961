import { PARENT } from '@store/actionTypes'
import {
  uploadDistribution,
  fetchAllParentConsents,
  uploadConsent,
  uploadCorrespondenceHistory,
  downloadParentConsentWorksheet,
  editSurveyStatus,
  deleteSurveyStatus,
} from '@api/parent'
import { PARENT_MSGS } from '@constants/strings'
import { get } from 'lodash'
import { message } from 'antd'
import moment from 'moment'

/**
 * Update Parent uploaded distribution History file
 */
export const setParentDistribution = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARENT.SET_UPLOAD_PARENT_DISTRIBUTION_HISTORY,
    async payload() {
      const resp = await uploadDistribution('parentConsent', formData)
      return resp.data
    },
  })

  return response
    .then(() => {
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get Parent Consent List
 */
export const getParentConsents = () => {
  return {
    type: PARENT.SET_PARENT_CONSENTS,
    async payload() {
      const resp = await fetchAllParentConsents()
      return resp.data
    },
  }
}

/**
 * Update Parent uploaded Consent file
 */
export const setParentConsent = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARENT.SET_UPLOAD_PARENT_CONSENT,
    async payload() {
      const resp = await uploadConsent('parentConsent', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getParentConsents())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Parent uploaded Correspondence History file
 */

export const setParentCorrespondenceHistory = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARENT.SET_UPLOAD_PARENT_CORR_HISTORY,
    async payload() {
      const resp = await uploadCorrespondenceHistory('parentConsent', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getParentConsents())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Update Parent Survey Contact Status i.e: EmailSent, SMSSent, callMade
 */
export const updateParentContactStatus = (data) => (dispatch) => {
  const response = dispatch({
    type: PARENT.UPDATE_PARENT_SURVEY_CONTACT_STATUS,
    async payload() {
      const resp = await editSurveyStatus(data.id, {
        emailDate: data.emailDate !== null ? moment(data.emailDate).format() : null,
        smsDate: data.smsDate !== null ? moment(data.smsDate).format() : null,
        callDate: data.callDate !== null ? moment(data.callDate).format() : null,
        invitationDate:
          data.invitationDate !== null ? moment(data.invitationDate).format() : null,
        endDate: data.endDate !== null ? moment(data.endDate).format() : null,
      })
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getParentConsents())
      message.success(
        get(response, 'message', PARENT_MSGS.EDIT_PARENT_CONTACT_STATUS_SUCCESS_MSG)
      )
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Delete Parent Consent Survey  i.e:  CompletionDate, ResponseId, Participant Info
 */
export const deleteParentConsentSurvey = (id) => (dispatch) => {
  const response = dispatch({
    type: PARENT.DELETE_PARENT_CONSENT_SURVEY,
    async payload() {
      const resp = await deleteSurveyStatus(id, 'parentConsent')
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getParentConsents())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get Parent Consent Survey Data Worksheet Download
 */

export const getParentConsentWorkSheetDownload = (data) => (dispatch) => {
  let payload
  data.length > 0
    ? (payload = {
        participantUuids: data,
      })
    : (payload = {})

  const response = dispatch({
    type: PARENT.SET_PARENT_DOWNLOAD_FILE,
    async payload() {
      const resp = await downloadParentConsentWorksheet('parentConsent', payload)
      const fileName = resp.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]/g, '')
      const fileDownload = require('js-file-download')
      fileDownload(resp.data, fileName)
      return resp.data
    },
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
