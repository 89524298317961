import { PARTICIPANT_MP1 } from '@store/actionTypes'
import {
  uploadDistribution,
  fetchAllParticipantMPOne,
  uploadConsent,
  uploadCorrespondenceHistory,
  downloadParticipantMPOneWorksheet,
  editSurveyStatus,
  deleteSurveyStatus,
} from '@api/participantMPOne'
import { PARTICIPANT_MP1_MSGS } from '@constants/strings'
import { get } from 'lodash'
import { message } from 'antd'
import moment from 'moment'

/**
 * Update Participant Measure Package 1 uploaded distribution History file
 */
export const setParticipantMPOneDistribution = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_DISTRIBUTION_HISTORY,
    async payload() {
      const resp = await uploadDistribution('participantMp1', formData)
      return resp.data
    },
  })

  return response
    .then(() => {
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get Participant Measure Package 1 List
 */
export const getMPOneParticipant = () => {
  return {
    type: PARTICIPANT_MP1.SET_PARTICIPANT_MP1_CONSENTS,
    async payload() {
      const resp = await fetchAllParticipantMPOne()
      return resp.data
    },
  }
}

/**
 * Update Participant Measure Package 1 uploaded Consent file
 */
export const setParticipantMPOneConsent = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_CONSENT,
    async payload() {
      const resp = await uploadConsent('participantMp1', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPOneParticipant())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant Measure Package 1 uploaded Correspondence History file
 */

export const setParticipantMPOneCorrespondenceHistory = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_CORR_HISTORY,
    async payload() {
      const resp = await uploadCorrespondenceHistory('participantMp1', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPOneParticipant())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Update Participant Measure Package 1 Survey Contact Status i.e: EmailSent, SMSSent, callMade
 */
export const updateParticipantMP1ContactStatus = (data) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT_MP1.UPDATE_PARTICIPANT_MP1_SURVEY_CONTACT_STATUS,
    async payload() {
      const resp = await editSurveyStatus(data.id, {
        emailDate: data.emailDate !== null ? moment(data.emailDate).format() : null,
        smsDate: data.smsDate !== null ? moment(data.smsDate).format() : null,
        callDate: data.callDate !== null ? moment(data.callDate).format() : null,
        invitationDate:
          data.invitationDate !== null ? moment(data.invitationDate).format() : null,
        endDate: data.endDate !== null ? moment(data.endDate).format() : null,
      })
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPOneParticipant())
      message.success(
        get(
          response,
          'message',
          PARTICIPANT_MP1_MSGS.EDIT_PARTICIPANT_MP1_CONTACT_STATUS_SUCCESS_MSG
        )
      )
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * DELETE Participant Measure Package 1 Consent Survey  i.e:  ResponseId, CompletionDate
 */
export const deleteParticipantMP1ConsentSurvey = (id) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT_MP1.DELETE_PARTICIPANT_MP1_SURVEY_CONSENT_SURVEY,
    async payload() {
      const resp = await deleteSurveyStatus(id, 'participantMp1')
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPOneParticipant())
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Get Participant Measure Package 1 Data Worksheet Download
 */

export const getParticipantMPOneWorkSheetDownload = (data) => (dispatch) => {
  let payload
  data.length > 0
    ? (payload = {
        participantUuids: data,
      })
    : (payload = {})

  const response = dispatch({
    type: PARTICIPANT_MP1.SET_PARTICIPANT_MP1_DOWNLOAD_FILE,
    async payload() {
      const resp = await downloadParticipantMPOneWorksheet('participantMp1', payload)
      const fileName = resp.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]/g, '')
      const fileDownload = require('js-file-download')
      fileDownload(resp.data, fileName)
      return resp.data
    },
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
