import React, { useEffect } from 'react'
import { getParticipant } from '@actions/participantActions'
import {
  getMPTwoParticipant,
  getParticipantMPTwoWorkSheetDownload,
  setParticipantMPTwoConsent,
  setParticipantMPTwoCorrespondenceHistory,
} from '@actions/participantMPTwoActions'
import ParticipantMPTwoList from '@containers/participantMPTwo/participantMPTwoList'
import connect from 'react-redux/es/connect/connect'
import useModal from '@src/hooks/useModal'
import { Spin } from 'antd'
import ParticipantModal from '@containers/participantModal'

function ParticipantContainer(props) {
  const {
    //States
    loading,
    participantMPTwo,
    //Dispatch
    getParticipant,
    getMPTwoParticipant,
    setParticipantMPTwoConsent,
    setParticipantMPTwoCorrespondenceHistory,
    getParticipantMPTwoWorkSheetDownload,
  } = props

  const { visible, handleVisibility } = useModal()

  useEffect(() => {
    getMPTwoParticipant()
  }, [getMPTwoParticipant])

  return (
    <div>
      <ParticipantModal
        visible={visible}
        handleVisibility={handleVisibility}
        parentComponent={'participant-mp2-consents'}
      />
      <Spin spinning={loading.uploadingSheet}>
        <ParticipantMPTwoList
          loading={loading}
          participantMPTwo={participantMPTwo}
          getParticipant={getParticipant}
          setParticipantMPTwoConsent={setParticipantMPTwoConsent}
          setParticipantMPTwoCorrespondenceHistory={
            setParticipantMPTwoCorrespondenceHistory
          }
          handleParticipantDetailsDisplay={handleVisibility}
          getParticipantMPTwoWorkSheetDownload={getParticipantMPTwoWorkSheetDownload}
        />
      </Spin>
    </div>
  )
}

const mapStateToProps = ({ combineReducer, user }) => ({
  participantToDetails: combineReducer.participantToDetails,
  participantMPTwo: combineReducer.participantMPTwo,
  loading: combineReducer.loading,
  user: user.user,
})

const mapDispatchToProps = {
  getParticipant: (id) => getParticipant(id),
  getMPTwoParticipant,
  getParticipantMPTwoWorkSheetDownload,
  setParticipantMPTwoCorrespondenceHistory,
  setParticipantMPTwoConsent,
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantContainer)
