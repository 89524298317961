import { PARTICIPANT_MP2 } from '@store/actionTypes'
import {
  uploadDistribution,
  fetchAllParticipantMPTwo,
  uploadConsent,
  uploadCorrespondenceHistory,
  downloadParticipantMPTwoWorksheet,
  editSurveyStatus,
  deleteSurveyStatus,
} from '@api/participantMPTwo'
import { PARTICIPANT_MP2_MSGS } from '@constants/strings'
import { get } from 'lodash'
import { message } from 'antd'
import moment from 'moment'

/**
 * Update Participant Measure Package 2 uploaded distribution History file
 */
export const setParticipantMPTwoDistribution = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_DISTRIBUTION_HISTORY,
    async payload() {
      const resp = await uploadDistribution('participantMp2', formData)
      return resp.data
    },
  })

  return response
    .then(() => {
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get Participant Measure Package 1 List
 */
export const getMPTwoParticipant = () => {
  return {
    type: PARTICIPANT_MP2.SET_PARTICIPANT_MP2_CONSENTS,
    async payload() {
      const resp = await fetchAllParticipantMPTwo()
      return resp.data
    },
  }
}

/**
 * Update Participant Measure Package 1 uploaded Consent file
 */
export const setParticipantMPTwoConsent = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_CONSENT,
    async payload() {
      const resp = await uploadConsent('participantMp2', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPTwoParticipant())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant Measure Package 1 uploaded Correspondence History file
 */

export const setParticipantMPTwoCorrespondenceHistory = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_CORR_HISTORY,
    async payload() {
      const resp = await uploadCorrespondenceHistory('participantMp2', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPTwoParticipant())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Update Participant Measure Package 1 Survey Contact Status i.e: EmailSent, SMSSent, callMade
 */
export const updateParticipantMP2ContactStatus = (data) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT_MP2.UPDATE_PARTICIPANT_MP2_SURVEY_CONTACT_STATUS,
    async payload() {
      const resp = await editSurveyStatus(data.id, {
        emailDate: data.emailDate !== null ? moment(data.emailDate).format() : null,
        smsDate: data.smsDate !== null ? moment(data.smsDate).format() : null,
        callDate: data.callDate !== null ? moment(data.callDate).format() : null,
        invitationDate:
          data.invitationDate !== null ? moment(data.invitationDate).format() : null,
        endDate: data.endDate !== null ? moment(data.endDate).format() : null,
      })
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPTwoParticipant())
      message.success(
        get(
          response,
          'message',
          PARTICIPANT_MP2_MSGS.EDIT_PARTICIPANT_MP2_CONTACT_STATUS_SUCCESS_MSG
        )
      )
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * DELETE Participant Measure Package 2 Consent Survey  i.e:  ResponseId, CompletionDate
 */
export const deleteParticipantMP2ConsentSurvey = (id) => (dispatch) => {
  const response = dispatch({
    type: PARTICIPANT_MP2.DELETE_PARTICIPANT_MP2_SURVEY_CONSENT_SURVEY,
    async payload() {
      const resp = await deleteSurveyStatus(id, 'participantMp2')
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPTwoParticipant())
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Get Participant Measure Package 2 Data Worksheet Download
 */

export const getParticipantMPTwoWorkSheetDownload = (data) => (dispatch) => {
  let payload
  data.length > 0
    ? (payload = {
        participantUuids: data,
      })
    : (payload = {})

  const response = dispatch({
    type: PARTICIPANT_MP2.SET_PARTICIPANT_MP2_DOWNLOAD_FILE,
    async payload() {
      const resp = await downloadParticipantMPTwoWorksheet('participantMp2', payload)
      const fileName = resp.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]/g, '')
      const fileDownload = require('js-file-download')
      fileDownload(resp.data, fileName)
      return resp.data
    },
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
