const ROUTES = {
  login: '/login',
  // register: '/register',
  dashboard: '/dashboard',
  parentConsent: '/parent-consent',
  participantConsent: '/participant-consent',
  distributionHistory: '/distribution-history',
  recallingSentence: '/recalling-sentences',
  participantList: '/participant-list',
  parentMPOne: '/parent-measure-pack-one',
  parentMPTwo: '/parent-measure-pack-two',
  participantMPOne: '/participant-measure-pack-one',
  participantMPTwo: '/participant-measure-pack-two',
  participantMPThree: '/participant-measure-pack-three',
  userManagement: '/user-management',
  downloadAllData: '/download-all-data',
}

export default ROUTES
