import React, { useEffect } from 'react'
import {
  getParticipant,
  setComment,
  updateComment,
  deleteComment,
  setAction,
  updateAction,
  updateActionComplete,
  setContact,
  setContactDetail,
  setPrimaryContact,
  setPrimaryContactDetail,
  setEditPrimaryContact,
  setEditOtherContact,
  setParticipantContactDetail,
  updateContactAccess,
  setSchoolDetail,
  updateParticipantDetail,
  updateParticipantContactStatus,
  deleteParticipantConsentSurvey,
} from '@actions/participantActions'

import {
  updateParentContactStatus,
  deleteParentConsentSurvey,
} from '@actions/parentActions'
import {
  getMPOneParent,
  setParentMPOneConsent,
  setParentMPOneCorrespondenceHistory,
  updateParentMP1ContactStatus,
  deleteParentMP1ConsentSurvey,
  getParentMPOneWorkSheetDownload,
} from '@actions/parentMPOneActions'

import {
  updateRecallingSentenceContactStatus,
  deleteRecallingSentenceTask,
} from '@actions/recallingSentenceActions'
import {
  updateParticipantMP1ContactStatus,
  deleteParticipantMP1ConsentSurvey,
} from '@actions/participantMPOneActions'
import {
  updateParticipantMP2ContactStatus,
  deleteParticipantMP2ConsentSurvey,
} from '@actions/participantMPTwoActions'
import {
  updateParticipantMP3ContactStatus,
  updateParticipantMP3VariablesStatus,
  deleteParticipantMP3ConsentSurvey,
} from '@actions/participantMPThreeActions'
import ParentMPOneList from '@containers/parentMPOne/parentMPOneList'
import connect from 'react-redux/es/connect/connect'
import ParticipantDetails from '@containers/participants/details'
import ModalComponent from '@components/modals'
import useModal from '@src/hooks/useModal'
import { Spin } from 'antd'

function ParentContainer(props) {
  const {
    //States
    loading,
    user,
    parentMPOne,
    participantToDetails,
    //Dispatch
    getParticipant,
    setComment,
    updateComment,
    deleteComment,
    setAction,
    updateActionComplete,
    updateAction,
    setContact,
    setContactDetail,
    setPrimaryContact,
    setPrimaryContactDetail,
    setEditPrimaryContact,
    setEditOtherContact,
    setParticipantContactDetail,
    updateContactAccess,
    setSchoolDetail,
    updateParticipantDetail,
    getMPOneParent,
    setParentMPOneConsent,
    setParentMPOneCorrespondenceHistory,
    updateParticipantContactStatus,
    updateParentContactStatus,
    updateRecallingSentenceContactStatus,
    getParentMPOneWorkSheetDownload,
    updateParentMP1ContactStatus,
    updateParticipantMP1ContactStatus,
    updateParticipantMP2ContactStatus,
    updateParticipantMP3ContactStatus,
    updateParticipantMP3VariablesStatus,
    deleteParticipantConsentSurvey,
    deleteParentConsentSurvey,
    deleteRecallingSentenceTask,
    deleteParentMP1ConsentSurvey,
    deleteParticipantMP1ConsentSurvey,
    deleteParticipantMP2ConsentSurvey,
    deleteParticipantMP3ConsentSurvey,
  } = props

  const { visible, handleVisibility } = useModal()

  useEffect(() => {
    getMPOneParent()
  }, [getMPOneParent])

  return (
    <div>
      <ModalComponent
        width={1200}
        visible={visible}
        handleVisibility={handleVisibility}
        title={
          participantToDetails
            ? 'Participant Details : ' +
              participantToDetails.participantId +
              ' - ' +
              participantToDetails.firstName +
              ' ' +
              participantToDetails.lastName
            : ''
        }
      >
        <ParticipantDetails
          participantToDetails={participantToDetails}
          user={user}
          setComment={setComment}
          updateComment={updateComment}
          deleteComment={deleteComment}
          setAction={setAction}
          updateAction={updateAction}
          updateActionComplete={updateActionComplete}
          setContact={setContact}
          setContactDetail={setContactDetail}
          setPrimaryContact={setPrimaryContact}
          setPrimaryContactDetail={setPrimaryContactDetail}
          setEditPrimaryContact={setEditPrimaryContact}
          setEditOtherContact={setEditOtherContact}
          setParticipantContactDetail={setParticipantContactDetail}
          updateContactAccess={updateContactAccess}
          setSchoolDetail={setSchoolDetail}
          updateParticipantDetail={updateParticipantDetail}
          updateParticipantContactStatus={updateParticipantContactStatus}
          updateParentContactStatus={updateParentContactStatus}
          updateRecallingSentenceContactStatus={updateRecallingSentenceContactStatus}
          updateParentMP1ContactStatus={updateParentMP1ContactStatus}
          updateParticipantMP1ContactStatus={updateParticipantMP1ContactStatus}
          updateParticipantMP2ContactStatus={updateParticipantMP2ContactStatus}
          updateParticipantMP3ContactStatus={updateParticipantMP3ContactStatus}
          updateParticipantMP3VariablesStatus={updateParticipantMP3VariablesStatus}
          deleteParticipantConsentSurvey={deleteParticipantConsentSurvey}
          deleteParentConsentSurvey={deleteParentConsentSurvey}
          deleteRecallingSentenceTask={deleteRecallingSentenceTask}
          deleteParentMP1ConsentSurvey={deleteParentMP1ConsentSurvey}
          deleteParticipantMP1ConsentSurvey={deleteParticipantMP1ConsentSurvey}
          deleteParticipantMP2ConsentSurvey={deleteParticipantMP2ConsentSurvey}
          deleteParticipantMP3ConsentSurvey={deleteParticipantMP3ConsentSurvey}
          parentComponent="parent-mp1-consents"
        />
      </ModalComponent>
      <Spin spinning={loading.uploadingSheet}>
        <ParentMPOneList
          loading={loading}
          parentMPOne={parentMPOne}
          getParticipant={getParticipant}
          setParentMPOneConsent={setParentMPOneConsent}
          setParentMPOneCorrespondenceHistory={setParentMPOneCorrespondenceHistory}
          handleParticipantDetailsDisplay={handleVisibility}
          getParentMPOneWorkSheetDownload={getParentMPOneWorkSheetDownload}
        />
      </Spin>
    </div>
  )
}

const mapStateToProps = ({ combineReducer, user }) => ({
  participants: combineReducer.participants,
  participantToDetails: combineReducer.participantToDetails,
  parentMPOne: combineReducer.parentMPOne,
  loading: combineReducer.loading,
  user: user.user,
})

const mapDispatchToProps = {
  getParticipant: (id) => getParticipant(id),
  setComment,
  updateComment,
  deleteComment,
  setAction,
  updateAction,
  updateActionComplete,
  setContact,
  setContactDetail,
  setPrimaryContact,
  setPrimaryContactDetail,
  setEditPrimaryContact,
  setEditOtherContact,
  setParticipantContactDetail,
  updateContactAccess,
  setSchoolDetail,
  updateParticipantDetail,
  getMPOneParent,
  setParentMPOneConsent,
  setParentMPOneCorrespondenceHistory,
  updateParticipantContactStatus,
  updateParentContactStatus,
  updateRecallingSentenceContactStatus,
  getParentMPOneWorkSheetDownload,
  updateParentMP1ContactStatus,
  updateParticipantMP1ContactStatus,
  updateParticipantMP2ContactStatus,
  updateParticipantMP3ContactStatus,
  updateParticipantMP3VariablesStatus,
  deleteParticipantConsentSurvey,
  deleteParentConsentSurvey,
  deleteRecallingSentenceTask,
  deleteParentMP1ConsentSurvey,
  deleteParticipantMP1ConsentSurvey,
  deleteParticipantMP2ConsentSurvey,
  deleteParticipantMP3ConsentSurvey,
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentContainer)
