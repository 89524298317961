import { Form, Input, Button, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}

function UserChangePasswordForm(props) {
  const { onUpdatePasswordSubmit, data } = props

  const [form] = Form.useForm()
  const [, forceUpdate] = useState() // To disable submit button at the beginning

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleFinish = (values) => {
    onUpdatePasswordSubmit(values)
  }
  /* eslint-disable */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  /* eslint-eable */

  return (
    <Form
      form={form}
      {...layout}
      name="nest-messages"
      onFinish={handleFinish}
      validateMessages={validateMessages}
      initialValues={{
        id: data?.id,
        name: data?.name,
        email: data?.email,
        notes: data?.notes,
        disabled: data?.disabled,
      }}
    >
      <Form.Item name="id" label="id" hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item name={['name']} label="User Name" hidden>
        <Input />
      </Form.Item>
      <Form.Item name={['email']} label="Email" hidden>
        <Input />
      </Form.Item>
      <Form.Item name={['notes']} label="Notes" hidden>
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Disable" name={['disabled']} hidden>
        <Switch defaultChecked={false} />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your new password!',
          },
          { min: 8, message: 'Password must be minimum 8 characters.' },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }

              return Promise.reject(
                'The two passwords that you entered do not match!'
              )
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} shouldUpdate>
        {() => (
          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                (!form.isFieldTouched('password') &&
                  !form.isFieldTouched('confirm')) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Submit
            </Button>
          </ButtonContainer>
        )}
      </Form.Item>
    </Form>
  )
}
export default UserChangePasswordForm
