import React from 'react'
import styled from 'styled-components'

const DivPad5 = styled.div`
  background-color: white;
  height: 100%;
  overflow-y: scroll;
`

export default function MainContentLayout(props) {
  const { children } = props

  return <DivPad5>{children} </DivPad5>
}
