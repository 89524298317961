import { Form, Input, Button, Checkbox } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 350px;
  }
`
function LoginForm(props) {
  const { onLogin } = props

  const onFinish = (values) => {
    onLogin(values)
  }

  /* eslint-disable */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
    },
  }
  /* eslint-enable */

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
            message: 'Please input your Email!',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <ButtonContainer>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
        </ButtonContainer>
      </Form.Item>
    </Form>
  )
}
export default LoginForm
