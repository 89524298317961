import { Button, Empty, message, Select } from 'antd'
import React, { useState, Fragment } from 'react'
import ModalComponent from '@components/modals'
import useModal from '@src/hooks/useModal'

const { Option } = Select
export default function FilterParentApplyContainer(props) {
  const { name, onApplyFilter, onRemoveFilterOne, onClearFilterAll } = props
  const { visible, handleVisibility } = useModal()

  //eslint-disable-next-line
  const [filterButtonClick, setFilterButtonClick] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null)

  let data = []
  if (name === 'parent-consent') {
    const savedFilter = JSON.parse(localStorage.getItem('parentConsentFilter'))
    if (savedFilter != null) {
      for (let i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
  } else if (name === 'participant-consent') {
    const savedFilter = JSON.parse(localStorage.getItem('participantConsentFilter'))
    if (savedFilter != null) {
      for (let i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
  } else if (name === 'recalling-sentence') {
    const savedFilter = JSON.parse(localStorage.getItem('recallingSentenceFilter'))
    if (savedFilter != null) {
      for (let i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
  } else if (name === 'parent-mpone-consent') {
    const savedFilter = JSON.parse(localStorage.getItem('parentMPOneFilter'))
    if (savedFilter != null) {
      for (let i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
  } else if (name === 'parent-mptwo-consent') {
    const savedFilter = JSON.parse(localStorage.getItem('parentMPTwoFilter'))
    if (savedFilter != null) {
      for (let i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
  } else if (name === 'participant-mpone-consent') {
    const savedFilter = JSON.parse(localStorage.getItem('participantMPOneFilter'))
    if (savedFilter != null) {
      for (let i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
  } else if (name === 'participant-mptwo-consent') {
    const savedFilter = JSON.parse(localStorage.getItem('participantMPTwoFilter'))
    if (savedFilter != null) {
      for (let i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
  } else if (name === 'participant-mpthree-consent') {
    const savedFilter = JSON.parse(localStorage.getItem('participantMPThreeFilter'))
    if (savedFilter != null) {
      for (let i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
  } else {
    const savedFilter = JSON.parse(localStorage.getItem('participantListFilter'))
    if (savedFilter != null) {
      for (let i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
  }

  const showModal = (e) => {
    setFilterButtonClick(true)
    handleVisibility(true)
  }

  //eslint-disable-next-line
  const onButtonSubmit = (e) => {
    if (selectedFilter != null) {
      onApplyFilter(selectedFilter)
    } else {
      message.warning('Please choose filter to Apply')
    }

    handleVisibility(false)
  }
  const onButtonFilterClearAll = () => {
    onClearFilterAll(selectedFilter)
    handleVisibility(false)
  }

  const onButtonFilterRemoveOne = (e) => {
    if (selectedFilter != null) {
      onRemoveFilterOne(selectedFilter)
    } else {
      message.warning('Please choose filter to clear')
    }
    handleVisibility(false)
  }

  const onChange = (value) => {
    setSelectedFilter(value)
  }

  return (
    <div>
      <Button
        id="ClearFilter"
        onClick={showModal}
        style={{
          backgroundColor: '#008385',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        Manage Save Filter
      </Button>
      <ModalComponent
        width={1000}
        visible={visible}
        handleVisibility={handleVisibility}
        title={
          name === 'parent-consent'
            ? "Parent's Consent Clear Filter Utility"
            : name === 'recalling-sentence'
            ? 'Recalling Sentence Task Clear Filter Utility'
            : name === 'parent-mpone-consent'
            ? "Parent's Measure Package 1 Clear Filter Utility"
            : name === 'participant-mpone-consent'
            ? "Participant's Measure Package 1 Clear Filter Utility"
            : name === 'participant-list'
            ? 'Participant List Clear Filter Utility'
            : "Participant's Consent Clear Filter Utility"
        }
      >
        <Fragment>
          {data.length > 0 ? (
            <div>
              Choose One from the Saved Filters:
              <span style={{ paddingLeft: '20px' }}>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a Filter"
                  optionFilterProp="data"
                  onChange={onChange}
                  allowClear={true}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {data.map(function (option, i) {
                    return (
                      <Option key={i} value={option.value}>
                        {option.label}
                      </Option>
                    )
                  })}
                </Select>
              </span>
              <div style={{ paddingTop: '20px' }}>
                <span style={{ paddingRight: '20px' }}>
                  <Button
                    id="ParentFilterRemoveOne"
                    onClick={onButtonFilterRemoveOne}
                    style={{
                      backgroundColor: '#008385',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Remove Choosen Filter
                  </Button>
                </span>
                <span>
                  <Button
                    id="ParentFilterClearAll"
                    onClick={onButtonFilterClearAll}
                    style={{
                      backgroundColor: '#008385',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Remove All
                  </Button>
                </span>
              </div>
            </div>
          ) : (
            <Empty
              style={{ marginTop: '50px', marginBottom: '50px' }}
              description={<b>No filter available</b>}
            />
          )}
        </Fragment>
      </ModalComponent>
    </div>
  )
}
