import 'primeicons/primeicons.css'
import 'primereact/resources/themes/nova-light/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import '@src/index.css'
import React, { useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import classNames from 'classnames'
import DownloadParentMP1 from '@components/download'
import UploadConsent from '@components/upload'
import UploadContactStatus from '@components/upload'
import ParentFilterClearContainer from '@containers/filters/filterClear'
import ParentFilterSaveContainer from '@containers/filters/filterSave'
import { Button, Divider, Tooltip, message, Select } from 'antd'
import { ProfileOutlined } from '@ant-design/icons'
import ModalComponent from '@components/modals'
import useModal from '@src/hooks/useModal'
import moment from 'moment'

const { Option } = Select

export default function ParentMPOneListContainer(props) {
  const {
    loading,
    parentMPOne,
    getParticipant,
    handleParticipantDetailsDisplay,
    setParentMPOneConsent,
    setParentMPOneCorrespondenceHistory,
    getParentMPOneWorkSheetDownload,
  } = props

  const dateRequired = ['Provided', 'Empty']
  const parentRecruitStatus = [
    'NOT_ELIGIBLE',
    'ELIGIBLE',
    'UNCONTACTABLE',
    'APPROACHED',
    'DID_NOT_RESPOND',
    'DECLINED',
    'RECRUITED',
    'WITHDREW',
    'RECRUITED_THEN_UNCONTACTABLE',
  ]

  const schoolStatus = [
    'Still going to school',
    'Graduated from Year 12',
    "Left school but didn't graduate",
    'Empty',
  ]
  const schoolYearStatus = [
    'Year 9 or below',
    'Year 10',
    'Year 11',
    'Year 12',
    'IB',
    'Empty',
  ]

  const dt = useRef(null)
  const { visible, handleVisibility } = useModal()
  const [globalFilter, setGlobalFilter] = useState(null)
  //eslint-disable-next-line
  const [parentMPOneConsentSubmit, setParentMPOneConsentSubmit] = useState(false)
  //eslint-disable-next-line
  const [parentMPOneCorrHistorySubmit, setParentMPOneCorrHistorySubmit] = useState(
    false
  )

  const [selectedParentRecruitStatus, setParentRecruitStatus] = useState(null)
  const [selectedSchoolStatus, setSelectedSchoolStatus] = useState(null)
  const [selectedSchoolYearStatus, setSelectedSchoolYearStatus] = useState(null)
  const [selectedInvitationDate, setSelectedInvitationDate] = useState(null)
  const [inputPostDayInvitation, setInputPostDayInvitation] = useState(null)
  const [inputPostDayEmail, setInputPostDayEmail] = useState(null)
  const [inputPostDaySms, setInputPostDaySms] = useState(null)
  const [inputPostDayCall, setInputPostDayCall] = useState(null)
  const [selectedEmailStatus, setSelectedEmailStatus] = useState(null)
  const [selectedSmsStatus, setSelectedSmsStatus] = useState(null)
  const [selectedCallStatus, setSelectedCallStatus] = useState(null)
  const [selectedCompletionDate, setSelectedCompletionDate] = useState(null)

  const [consentButtonClick, setConsentButtonClick] = useState(false)
  //eslint-disable-next-line
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [savedFilterData, setSavedDataFilter] = useState([])
  const [filters, setFilters] = useState(null)

  useEffect(() => {
    reInitialiseSelectData()
  }, [])

  const onUploadConsent = (file) => {
    setParentMPOneConsent(file)
      .then(() => setParentMPOneConsentSubmit(true))
      .catch(() => setParentMPOneConsentSubmit(false))
    handleVisibility(false)
  }

  const onUploadContactStatus = (file) => {
    handleVisibility(false)
    setParentMPOneCorrespondenceHistory(file)
      .then(() => setParentMPOneCorrHistorySubmit(true))
      .catch(() => setParentMPOneCorrHistorySubmit(false))
  }

  const showModal = (e) => {
    e.currentTarget.id === 'ParentConsent'
      ? setConsentButtonClick(true)
      : setConsentButtonClick(false)
    handleVisibility(true)
  }

  const onSaveFilter = (filterName) => {
    const savedFilter = JSON.parse(localStorage.getItem('parentMPOneFilter'))
    let arr = []
    if (filters != null) {
      if (savedFilter != null && savedFilter.length > 0) {
        // Check the filters is already in the local Storage
        for (var i = 0; i < savedFilter.length; i++) {
          if (JSON.stringify(savedFilter[i]) === JSON.stringify(filters)) {
            break
          } else {
            let final_obj = { filterName: filterName, filters: filters }
            savedFilter.push(final_obj)
            localStorage.setItem('parentMPOneFilter', JSON.stringify(savedFilter))
            reInitialiseSelectData()
            message.success('The choosen filter has been saved successfully!')
            break
          }
        }
      } else {
        // For the first time when no filter is set before
        let final_obj = { filterName: filterName, filters: filters }
        arr.push(final_obj)
        localStorage.setItem('parentMPOneFilter', JSON.stringify(arr))
        reInitialiseSelectData()
        message.success('The choosen filter has been saved successfully!')
      }
    } else {
      message.warning('Please apply the filter on the table then save filter!')
    }
  }

  const onFilter = (e) => {
    setFilters(e.filters)
  }

  const reInitialiseSelectData = () => {
    let data = []
    const savedFilter = JSON.parse(localStorage.getItem('parentMPOneFilter'))
    if (savedFilter != null) {
      for (var i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters),
        })
      }
    }
    setSavedDataFilter(data)
  }

  const onApplyFilter = (filter) => {
    if (filter !== null) {
      let selectedKeys = Object.entries(JSON.parse(filter))
      for (var i = 0; i < selectedKeys.length; i++) {
        if (selectedKeys[i][0] === 'schoolStatus') {
          setSelectedSchoolStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'parentRecruitmentStatus') {
          setParentRecruitStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'invitationDate') {
          setSelectedInvitationDate(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'completionDate') {
          setSelectedCompletionDate(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'daysPostInvitation') {
          setInputPostDayInvitation(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'daysPostEmail') {
          setInputPostDayEmail(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'daysPostSms') {
          setInputPostDaySms(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'daysPostCall') {
          setInputPostDayCall(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'schoolYear') {
          setSelectedSchoolYearStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'emailDate') {
          setSelectedEmailStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'callDate') {
          setSelectedCallStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'smsDate') {
          setSelectedSmsStatus(selectedKeys[i][1].value)
        }
      }
      setFilters(JSON.parse(filter))
      message.success('The choosen filter has been applied successfully!')
    } else {
      setFilters(null)
    }
  }
  const onClearFilterAll = () => {
    localStorage.removeItem('parentMPOneFilter')
    message.success('All filters have been removed successfully!')
    reInitialiseSelectData()
  }

  const onRemoveFilterOne = (choosenFilter) => {
    const allSavedFilter = JSON.parse(localStorage.getItem('parentMPOneFilter'))
    for (var i = 0; i < allSavedFilter.length; i++) {
      if (JSON.stringify(allSavedFilter[i].filters) === choosenFilter) {
        allSavedFilter.splice(i, 1)
        localStorage.removeItem('parentMPOneFilter')
        localStorage.setItem('parentMPOneFilter', JSON.stringify(allSavedFilter))
        message.success('The choosen filter has been removed successfully!')
        break
      }
    }
    reInitialiseSelectData()
  }

  const onChangeFilter = (value) => {
    if (value !== undefined) {
      setSelectedFilter(value)
      onApplyFilter(value)
    } else {
      setSelectedFilter(null)
      clearAllFilterValues()
      onApplyFilter(null)
    }
  }
  const clearAllFilterValues = () => {
    setParentRecruitStatus(null)
    setSelectedSchoolStatus(null)
    setSelectedSchoolYearStatus(null)
    setSelectedInvitationDate(null)
    setInputPostDayInvitation(null)
    setInputPostDayEmail(null)
    setInputPostDaySms(null)
    setInputPostDayCall(null)
    setSelectedEmailStatus(null)
    setSelectedSmsStatus(null)
    setSelectedCallStatus(null)
    setSelectedCompletionDate(null)
    setGlobalFilter(null)
  }

  const clearAllFilterTable = () => {
    setSelectedFilter(null)
    clearAllFilterValues()
    onApplyFilter(null)
  }
  const renderHeader = () => {
    return (
      <div>
        Parent Measure Package - 1
        <div style={{ display: 'flex' }}>
          <span>
            <p style={{ width: '1550px' }}></p>
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ width: '25px' }}></p>
          <div>
            <ParentFilterSaveContainer
              name="parent-mpone-consent"
              onSaveFilter={onSaveFilter}
              filters={filters}
            />
          </div>
          <p style={{ width: '25px' }}></p>
          <div>
            <Select
              showSearch
              style={{ width: 220 }}
              placeholder="Choose a Filter to Apply"
              optionFilterProp="savedFilterData"
              onChange={onChangeFilter}
              allowClear={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {savedFilterData.map(function (option, i) {
                return (
                  <Option key={i} value={option.value}>
                    {option.label}
                  </Option>
                )
              })}
            </Select>
          </div>
          <p style={{ width: '25px' }}></p>
          <div>
            <ParentFilterClearContainer
              name="parent-mpone-consent"
              onRemoveFilterOne={onRemoveFilterOne}
              onClearFilterAll={onClearFilterAll}
            />
          </div>
          <p style={{ width: '25px' }}></p>
          <div>
            <Button
              onClick={clearAllFilterTable}
              style={{
                backgroundColor: '#008385',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Clear Filters
            </Button>
          </div>
          <p style={{ width: '25px' }}></p>
          <p style={{ width: '530px' }}></p>
          <div className="p-datatable-globalfilter-container">
            <InputText
              type="search"
              onInput={(e) => {
                setGlobalFilter(e.target.value)
              }}
              placeholder="Global Search"
            />
          </div>
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <span>
            <p style={{ width: '1550px' }}></p>
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ width: '25px' }}></p>
          <div>
            <Button
              id="ParentConsent"
              onClick={showModal}
              style={{
                backgroundColor: '#008385',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Upload Responses
            </Button>
            <ModalComponent
              width={800}
              visible={visible}
              handleVisibility={handleVisibility}
              title={
                consentButtonClick
                  ? "Parent's Measure Package 1 Responses Upload"
                  : 'Parent Measure Package 1 Correspondence History Upload'
              }
            >
              {consentButtonClick ? (
                <UploadConsent
                  name="parent-mpone-consent"
                  labelType="PARENT"
                  onUploadClick={onUploadConsent}
                  loading={loading}
                />
              ) : (
                <UploadContactStatus
                  name="parent-mpone-contact"
                  labelType="PARENT"
                  onUploadClick={onUploadContactStatus}
                  loading={loading}
                />
              )}
            </ModalComponent>
          </div>
          <p style={{ width: '25px' }}></p>
          <div>
            <Button
              id="ParentContact"
              onClick={showModal}
              style={{
                backgroundColor: '#008385',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Upload Correspondence History
            </Button>
          </div>
          <p style={{ width: '25px' }}></p>
          <div>
            {parentMPOne && (
              <DownloadParentMP1
                rawData={parentMPOne}
                buttonLabel="Download Worksheet"
                getDataDownload={getParentMPOneWorkSheetDownload}
                componentId="parent-mpone-consent"
                loading={loading}
                filters={filters}
              />
            )}
          </div>
          <p style={{ width: '25px' }}></p>
          <p style={{ width: '25px' }}></p>
          <p style={{ width: '800px' }}></p>
        </div>
      </div>
    )
  }

  const operationBodyTemplate = (rowData) => {
    return (
      <span>
        <Tooltip title="Participant details">
          <span>
            <ProfileOutlined
              onClick={() => {
                getParticipant(rowData.id)
                handleParticipantDetailsDisplay()
              }}
            />
          </span>
          <Divider type="vertical" />
        </Tooltip>
      </span>
    )
  }

  // School Status

  const statusSchoolBodyTemplate = (rowData) => {
    let schoolStatus = rowData.schoolStatus !== null ? rowData.schoolStatus : '--'
    return (
      <span
        className={classNames(
          'participant-badge',
          'school-status-' +
            schoolStatus.toUpperCase().replace(/ /g, '_').replace("'", '')
        )}
      >
        {schoolStatus}
      </span>
    )
  }
  const renderSchoolStatusFilter = () => {
    return (
      <MultiSelect
        value={selectedSchoolStatus}
        options={schoolStatus}
        itemTemplate={statusSchoolTemplate}
        onChange={onStatusSchoolFilterChange}
        placeholder="Search By School Status"
        className="p-column-filter"
      />
    )
  }
  const statusSchoolTemplate = (option) => {
    return (
      <span
        className={classNames(
          'participant-badge',
          'school-status-' + option.toUpperCase().replace(/ /g, '_').replace("'", '')
        )}
      >
        {option}
      </span>
    )
  }
  const onStatusSchoolFilterChange = (event) => {
    dt.current.filter(event.value, 'schoolStatus', 'custom')
    setSelectedSchoolStatus(event.value)
  }
  const filterSchoolStatus = (value, filter) => {
    if (value === null && filter.includes('Empty')) {
      return true
    } else if (
      value === 'Still going to school' &&
      filter.includes('Still going to school')
    ) {
      return true
    } else if (
      value === 'Graduated from Year 12' &&
      filter.includes('Graduated from Year 12')
    ) {
      return true
    } else if (
      value === "Left school but didn't graduate" &&
      filter.includes("Left school but didn't graduate")
    ) {
      return true
    }
  }
  // End School Status

  // School Year Status

  const statusSchoolYearBodyTemplate = (rowData) => {
    let schoolYear =
      rowData.schoolYearLevel !== null ? rowData.schoolYearLevel : '--'
    return (
      <span
        className={classNames(
          'participant-badge',
          'school-status-year-' + schoolYear.toUpperCase().replace(/ /g, '_')
        )}
      >
        {schoolYear}
      </span>
    )
  }
  const renderSchoolYearStatusFilter = () => {
    return (
      <MultiSelect
        value={selectedSchoolYearStatus}
        options={schoolYearStatus}
        itemTemplate={statusSchoolYearItemTemplate}
        onChange={onStatusSchoolYearFilterChange}
        placeholder="Search By School Year"
        className="p-column-filter"
      />
    )
  }
  const statusSchoolYearItemTemplate = (option) => {
    return (
      <span
        className={classNames(
          'participant-badge',
          'school-status-year-' + option.toUpperCase().replace(/ /g, '_')
        )}
      >
        {option}
      </span>
    )
  }
  const onStatusSchoolYearFilterChange = (event) => {
    dt.current.filter(event.value, 'schoolYearLevel', 'custom')
    setSelectedSchoolYearStatus(event.value)
  }

  const filterSchoolYear = (value, filter) => {
    if (value === null && filter.includes('Empty')) {
      return true
    } else if (value === 'Year 10' && filter.includes('Year 10')) {
      return true
    } else if (value === 'Year 9 or below' && filter.includes('Year 9 or below')) {
      return true
    } else if (value === 'Year 11' && filter.includes('Year 11')) {
      return true
    } else if (value === 'Year 12' && filter.includes('Year 12')) {
      return true
    } else if (value === 'IB' && filter.includes('IB')) {
      return true
    }
  }
  // End School Year Status

  //  Email Status
  const statusEmailBodyTemplate = (rowData) => {
    let emailSent =
      rowData.emailDate !== null && rowData.emailDate !== undefined
        ? moment(rowData.emailDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + emailSent)}>
        {emailSent}
      </span>
    )
  }
  const renderEmailStatusFilter = () => {
    return (
      <Dropdown
        value={selectedEmailStatus}
        options={dateRequired}
        onChange={onStatusEmailFilterChange}
        itemTemplate={statusItemTemplate}
        showClear
        placeholder="Search By Email Status"
        className="p-column-filter"
      />
    )
  }
  const statusItemTemplate = (option) => {
    return (
      <span className={classNames('participant-badge', 'status-' + option)}>
        {option}
      </span>
    )
  }
  const onStatusEmailFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'emailDate', 'custom')
    } else {
      dt.current.filter(event.value, 'emailDate', 'custom')
    }
    setSelectedEmailStatus(event.value)
  }
  const filterEmailDate = (value) => {
    if (selectedEmailStatus === 'Provided') {
      return value != null
    } else if (selectedEmailStatus === 'Empty') {
      return value == null
    }
  }
  // End Email Status

  //  Sms Status
  const statusSmsBodyTemplate = (rowData) => {
    let smsSent =
      rowData.smsDate !== null && rowData.smsDate !== undefined
        ? moment(rowData.smsDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + smsSent)}>
        {smsSent}
      </span>
    )
  }
  const renderSmsStatusFilter = () => {
    return (
      <Dropdown
        value={selectedSmsStatus}
        options={dateRequired}
        onChange={onStatusSmsFilterChange}
        itemTemplate={statusItemTemplate}
        showClear
        placeholder="Search By SMS Status"
        className="p-column-filter"
      />
    )
  }
  const onStatusSmsFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'smsDate', 'custom')
    } else {
      dt.current.filter(event.value, 'smsDate', 'custom')
    }
    setSelectedSmsStatus(event.value)
  }
  const filterSmsDate = (value) => {
    if (selectedSmsStatus === 'Provided') {
      return value != null
    } else if (selectedSmsStatus === 'Empty') {
      return value == null
    }
  }
  // End SMS Status

  //  Call Status
  const statusCallBodyTemplate = (rowData) => {
    let callMade =
      rowData.callDate !== null && rowData.callDate !== undefined
        ? moment(rowData.callDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + callMade)}>
        {callMade}
      </span>
    )
  }

  const renderCallStatusFilter = () => {
    return (
      <Dropdown
        value={selectedCallStatus}
        options={dateRequired}
        onChange={onStatusCallFilterChange}
        itemTemplate={statusItemTemplate}
        showClear
        placeholder="Search By Call Status"
        className="p-column-filter"
      />
    )
  }
  const onStatusCallFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'callDate', 'custom')
    } else {
      dt.current.filter(event.value, 'callDate', 'custom')
    }
    setSelectedCallStatus(event.value)
  }

  const filterCallMadeDate = (value) => {
    if (selectedCallStatus === 'Provided') {
      return value != null
    } else if (selectedCallStatus === 'Empty') {
      return value == null
    }
  }
  // End SMS Status

  // Start Parent Recruitment Status

  const statusBodyParentRecruitTemplate = (rowData) => {
    let parentRecruitmentStatus =
      rowData.parentRecruitmentStatus !== null
        ? rowData.parentRecruitmentStatus
        : '--'
    return (
      <span
        className={classNames(
          'participant-badge',
          'ParentRecruitmentStatus-' + parentRecruitmentStatus
        )}
      >
        {parentRecruitmentStatus}
      </span>
    )
  }

  const renderStatusParentRecruitFilter = () => {
    return (
      <Dropdown
        value={selectedParentRecruitStatus}
        options={parentRecruitStatus}
        onChange={onParentRecruitStatusFilterChange}
        itemTemplate={statusParentRecruitItemTemplate}
        showClear
        placeholder="Search Status"
        className="p-column-filter"
      />
    )
  }

  const statusParentRecruitItemTemplate = (option) => {
    return (
      <span
        className={classNames(
          'participant-badge',
          'ParentRecruitmentStatus-' + option
        )}
      >
        {option}
      </span>
    )
  }
  const onParentRecruitStatusFilterChange = (event) => {
    dt.current.filter(event.value, 'parentRecruitmentStatus', 'equals')
    setParentRecruitStatus(event.value)
  }
  /// end Parent Recruitment Status
  //  CompletionDate Status
  const statusCompletionBodyTemplate = (rowData) => {
    let compDate =
      rowData.completionDate !== null && rowData.completionDate !== undefined
        ? moment(rowData.completionDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + compDate)}>
        {compDate}
      </span>
    )
  }
  const renderCompletionDateFilter = () => {
    return (
      <Dropdown
        value={selectedCompletionDate}
        options={dateRequired}
        onChange={onCompletionDateFilterChange}
        itemTemplate={statusItemTemplate}
        showClear
        placeholder="Search CompletionDate"
        className="p-column-filter"
      />
    )
  }

  const onCompletionDateFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'completionDate', 'custom')
    } else {
      dt.current.filter(event.value, 'completionDate', 'custom')
    }
    setSelectedCompletionDate(event.value)
  }

  const filterCompleteDate = (value) => {
    if (selectedCompletionDate === 'Provided') {
      return value != null
    } else if (selectedCompletionDate === 'Empty') {
      return value == null
    }
  }
  ///
  //  InvitationDate Status
  const statusInvitationBodyTemplate = (rowData) => {
    let invDate =
      rowData.invitationDate !== null && rowData.invitationDate !== undefined
        ? moment(rowData.invitationDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + invDate)}>
        {invDate}
      </span>
    )
  }

  const renderInviteDateStatusFilter = () => {
    return (
      <Dropdown
        value={selectedInvitationDate}
        options={dateRequired}
        onChange={onInvitationDateFilterChange}
        itemTemplate={actionInviteTemplate}
        showClear
        placeholder="Search Invitation Sent"
        className="p-column-filter"
      />
    )
  }

  const actionInviteTemplate = (option) => {
    return (
      <span className={classNames('participant-badge', 'p-status-' + option)}>
        {option}
      </span>
    )
  }

  const onInvitationDateFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'invitationDate', 'custom')
    } else {
      dt.current.filter(event.value, 'invitationDate', 'custom')
    }
    setSelectedInvitationDate(event.value)
  }

  const filterInviteDate = (value) => {
    // console.log("filterInviteDate", value)
    // console.log('filterInviteDate selectedInvitationDate', selectedInvitationDate)
    if (selectedInvitationDate === 'Provided') {
      return value != null
    } else if (selectedInvitationDate === 'Empty') {
      return value == null
    }
  }
  ///

  //  DaysPost Invitation Status
  const daysPostInvitationBodyTemplate = (rowData) => {
    let daysPostInv =
      rowData.daysPostInvitation !== null ? rowData.daysPostInvitation : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + daysPostInv)}>
        {daysPostInv}
      </span>
    )
  }

  const renderDaysPostInviteFilter = () => {
    return (
      <InputText
        type="number"
        style={{ width: '90px' }}
        onChange={onChangeDaysPostInterval}
        value={inputPostDayInvitation !== null ? inputPostDayInvitation : '>'}
        placeholder="Search By: > "
      />
    )
  }
  const onChangeDaysPostInterval = (e) => {
    dt.current.filter(e.target.value, 'daysPostInvitation', 'gte')
    setInputPostDayInvitation(e.target.value)
  }
  ///

  //  DaysPost Email Date
  const daysPostEmailBodyTemplate = (rowData) => {
    let daysPostEmail = rowData.daysPostEmail !== null ? rowData.daysPostEmail : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + daysPostEmail)}>
        {daysPostEmail}
      </span>
    )
  }

  const renderDaysPostEmailFilter = () => {
    return (
      <InputText
        type="number"
        style={{ width: '90px' }}
        onChange={onChangeDaysPostEmail}
        value={inputPostDayEmail !== null ? inputPostDayEmail : '>'}
        placeholder="Search By: > "
      />
    )
  }
  const onChangeDaysPostEmail = (e) => {
    dt.current.filter(e.target.value, 'daysPostEmail', 'gte')
    setInputPostDayEmail(e.target.value)
  }
  ///
  //  DaysPost SMS Date
  const daysPostSmsBodyTemplate = (rowData) => {
    let daysPostSms = rowData.daysPostSms !== null ? rowData.daysPostSms : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + daysPostSms)}>
        {daysPostSms}
      </span>
    )
  }

  const renderDaysPostSmsFilter = () => {
    return (
      <InputText
        type="number"
        style={{ width: '90px' }}
        onChange={onChangeDaysPostSms}
        value={inputPostDaySms !== null ? inputPostDaySms : '>'}
        placeholder="Search By: > "
      />
    )
  }
  const onChangeDaysPostSms = (e) => {
    dt.current.filter(e.target.value, 'daysPostSms', 'gte')
    setInputPostDaySms(e.target.value)
  }
  ///
  //  DaysPost Call Date
  const daysPostCallBodyTemplate = (rowData) => {
    let daysPostCall = rowData.daysPostCall !== null ? rowData.daysPostCall : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + daysPostCall)}>
        {daysPostCall}
      </span>
    )
  }

  const renderDaysPostCallFilter = () => {
    return (
      <InputText
        type="number"
        style={{ width: '90px' }}
        onChange={onChangeDaysPostCall}
        value={inputPostDayCall !== null ? inputPostDayCall : '>'}
        placeholder="Search By: > "
      />
    )
  }
  const onChangeDaysPostCall = (e) => {
    dt.current.filter(e.target.value, 'daysPostCall', 'gte')
    setInputPostDayCall(e.target.value)
  }
  ///
  const onTableRowClick = (rowData) => {
    getParticipant(rowData.data.id)
    handleParticipantDetailsDisplay()
  }
  const header = renderHeader()
  const statusSchoolFilter = renderSchoolStatusFilter()
  const statusSchoolYearStatusFilter = renderSchoolYearStatusFilter()
  const statusParentRecruitFilter = renderStatusParentRecruitFilter()
  const statusCompletionDateFilter = renderCompletionDateFilter()
  const statusEmailFilter = renderEmailStatusFilter()
  const statusSmsFilter = renderSmsStatusFilter()
  const statusCallFilter = renderCallStatusFilter()
  const statusInviteDateFilter = renderInviteDateStatusFilter()
  const daysPostInviteFilter = renderDaysPostInviteFilter()
  const daysPostEmailFilter = renderDaysPostEmailFilter()
  const daysPostSmsFilter = renderDaysPostSmsFilter()
  const daysPostCallFilter = renderDaysPostCallFilter()
  const footer = renderFooter()

  return (
    <div className="datatable-doc-demo" style={{ width: '2000px' }}>
      <DataTable
        ref={dt}
        value={parentMPOne}
        header={header}
        footer={footer}
        filters={filters}
        onFilter={onFilter}
        responsive
        className="p-datatable-customers"
        dataKey="id"
        rowHover={true}
        globalFilter={globalFilter}
        onRowClick={onTableRowClick}
        paginator
        rows={10}
        emptyMessage="No Parents Measure Package 1 found"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 100, 200, 300, parentMPOne?.length]}
      >
        <Column
          field="participantId"
          header="Participant ID"
          headerStyle={{ width: '80px' }}
          sortable
          filter
          filterPlaceholder="Search by Participant ID"
        />
        <Column
          field="firstName"
          header="Parent First Name"
          headerStyle={{ width: '100px' }}
          sortable
          filter
          filterPlaceholder="Search by First Name"
        />
        <Column
          field="lastName"
          header="Parent Last Name"
          headerStyle={{ width: '100px' }}
          sortable
          filter
          filterPlaceholder="Search by Last Name"
        />
        <Column
          field="parentRecruitmentStatus"
          header="Parent Recruitment Status"
          headerStyle={{ width: '150px' }}
          body={statusBodyParentRecruitTemplate}
          sortable
          filter
          filterElement={statusParentRecruitFilter}
          filterPlaceholder="Search by Parent Recruitment Status"
        />
        <Column
          field="schoolStatus"
          header="School Status"
          headerStyle={{ width: '210px' }}
          body={statusSchoolBodyTemplate}
          sortable
          filter
          filterMatchMode="custom"
          filterFunction={filterSchoolStatus}
          filterElement={statusSchoolFilter}
          filterPlaceholder="Search by School Status"
        />
        <Column
          field="schoolYearLevel"
          header="School Year Level"
          headerStyle={{ width: '150px' }}
          body={statusSchoolYearBodyTemplate}
          sortable
          filter
          filterMatchMode="custom"
          filterFunction={filterSchoolYear}
          filterElement={statusSchoolYearStatusFilter}
          filterPlaceholder="Search by School Year Level"
        />
        <Column
          field="invitationDate"
          header="Invitation Sent"
          headerStyle={{ width: '120px' }}
          body={statusInvitationBodyTemplate}
          sortable
          filter
          filterFunction={filterInviteDate}
          filterElement={statusInviteDateFilter}
          filterPlaceholder="Search by Invitation Date"
        />
        <Column
          field="daysPostInvitation"
          header="Days Post Invitation"
          headerStyle={{ width: '100px' }}
          body={daysPostInvitationBodyTemplate}
          sortable
          filter
          filterElement={daysPostInviteFilter}
          filterPlaceholder="> Search"
        />
        <Column
          field="emailDate"
          header="Reminder Email Sent"
          headerStyle={{ width: '120px' }}
          body={statusEmailBodyTemplate}
          sortable
          filter
          filterFunction={filterEmailDate}
          filterElement={statusEmailFilter}
          filterPlaceholder="Search by Email Sent"
        />
        <Column
          field="daysPostEmail"
          header="Days Post Email"
          headerStyle={{ width: '100px' }}
          body={daysPostEmailBodyTemplate}
          sortable
          filter
          filterElement={daysPostEmailFilter}
          filterPlaceholder="> Search"
        />
        <Column
          field="smsDate"
          header="Reminder SMS Sent"
          headerStyle={{ width: '120px' }}
          body={statusSmsBodyTemplate}
          sortable
          filter
          filterFunction={filterSmsDate}
          filterElement={statusSmsFilter}
          filterPlaceholder="Search by SMS Sent"
        />
        <Column
          field="daysPostSms"
          header="Days Post Sms"
          headerStyle={{ width: '100px' }}
          body={daysPostSmsBodyTemplate}
          sortable
          filter
          filterElement={daysPostSmsFilter}
          filterPlaceholder="> Search"
        />
        <Column
          field="callDate"
          header="Reminder Call Made"
          headerStyle={{ width: '120px' }}
          body={statusCallBodyTemplate}
          sortable
          filter
          filterFunction={filterCallMadeDate}
          filterElement={statusCallFilter}
          filterPlaceholder="Search by Call Made"
        />
        <Column
          field="daysPostCall"
          header="Days Post Call"
          headerStyle={{ width: '100px' }}
          body={daysPostCallBodyTemplate}
          sortable
          filter
          filterElement={daysPostCallFilter}
          filterPlaceholder="> Search"
        />
        <Column
          field="completionDate"
          header="Completion Date"
          headerStyle={{ width: '120px' }}
          body={statusCompletionBodyTemplate}
          sortable
          filter
          filterFunction={filterCompleteDate}
          filterElement={statusCompletionDateFilter}
          filterPlaceholder="Search by Completion Date"
        />
        <Column
          body={operationBodyTemplate}
          headerStyle={{ width: '8em', textAlign: 'center' }}
          header="Operations"
          bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
        />
      </DataTable>
    </div>
  )
}
