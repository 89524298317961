import { PARENT_MP2 } from '@store/actionTypes'
import {
  uploadDistribution,
  fetchAllParentMPTwo,
  uploadConsent,
  uploadCorrespondenceHistory,
  downloadParentMPTwoWorksheet,
  editSurveyStatus,
  deleteSurveyStatus,
} from '@api/parentMPTwo'
import { PARENT_MP2_MSGS } from '@constants/strings'
import { get } from 'lodash'
import { message } from 'antd'
import moment from 'moment'

/**
 * Update Parent Measure Package 2 uploaded distribution History file
 */
export const setParentMPTwoDistribution = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARENT_MP2.SET_UPLOAD_PARENT_MP2_DISTRIBUTION_HISTORY,
    async payload() {
      const resp = await uploadDistribution('parentMp2', formData)
      return resp.data
    },
  })

  return response
    .then(() => {
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get Parent Measure Package 2 List
 */
export const getMPTwoParent = () => {
  return {
    type: PARENT_MP2.SET_PARENT_MP2_CONSENTS,
    async payload() {
      const resp = await fetchAllParentMPTwo()
      return resp.data
    },
  }
}

/**
 * Update Parent Measure Package 2 uploaded Consent file
 */
export const setParentMPTwoConsent = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARENT_MP2.SET_UPLOAD_PARENT_MP2_CONSENT,
    async payload() {
      const resp = await uploadConsent('parentMp2', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPTwoParent())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Parent Measure Package 2 uploaded Correspondence History file
 */

export const setParentMPTwoCorrespondenceHistory = (data) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: PARENT_MP2.SET_UPLOAD_PARENT_MP2_CORR_HISTORY,
    async payload() {
      const resp = await uploadCorrespondenceHistory('parentMp2', formData)
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPTwoParent())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Update Parent Measure Package 2 Survey Contact Status i.e: EmailSent, SMSSent, callMade
 */
export const updateParentMP2ContactStatus = (data) => (dispatch) => {
  const response = dispatch({
    type: PARENT_MP2.UPDATE_PARENT_MP2_SURVEY_CONTACT_STATUS,
    async payload() {
      const resp = await editSurveyStatus(data.id, {
        emailDate: data.emailDate !== null ? moment(data.emailDate).format() : null,
        smsDate: data.smsDate !== null ? moment(data.smsDate).format() : null,
        callDate: data.callDate !== null ? moment(data.callDate).format() : null,
        invitationDate:
          data.invitationDate !== null ? moment(data.invitationDate).format() : null,
        endDate: data.endDate !== null ? moment(data.endDate).format() : null,
      })
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPTwoParent())
      message.success(
        get(
          response,
          'message',
          PARENT_MP2_MSGS.EDIT_PARENT_MP2_CONTACT_STATUS_SUCCESS_MSG
        )
      )
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Delete Parent Measure Package 2 Survey Survey i.e:   responseId, completionDate
 */
export const deleteParentMP2ConsentSurvey = (id) => (dispatch) => {
  const response = dispatch({
    type: PARENT_MP2.DELETE_PARENT_MP2_SURVEY_CONSENT_SURVEY,
    async payload() {
      const resp = await deleteSurveyStatus(id, 'parentMp2')
      return resp.data
    },
  })

  return response
    .then(async () => {
      dispatch(getMPTwoParent())
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Get Parent Measure Package 2 Data Worksheet Download
 */

export const getParentMPTwoWorkSheetDownload = (data) => (dispatch) => {
  let payload
  data.length > 0
    ? (payload = {
        participantUuids: data,
      })
    : (payload = {})

  const response = dispatch({
    type: PARENT_MP2.SET_PARENT_MP2_DOWNLOAD_FILE,
    async payload() {
      const resp = await downloadParentMPTwoWorksheet('parentMp2', payload)
      const fileName = resp.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]/g, '')
      const fileDownload = require('js-file-download')
      fileDownload(resp.data, fileName)
      return resp.data
    },
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
