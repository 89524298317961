import EP from './endpoints'
import api from './api'

export function editAction(id, data) {
  return api.put(EP.PARTICIPANT_UPDATE_ACTION(id), data)
}

export function editActionMarkComplete(id, data) {
  return api.put(EP.PARTICIPANT_UPDATE_ACTION_MARK_COMPLETE(id), data)
}
export function createAction(participantId, data) {
  return api.post(EP.PARTICIPANT_ADD_ACTION(participantId), data)
}
