import { Button, Input, Form, Alert } from 'antd'
import React, { useState } from 'react'
import ModalComponent from '@components/modals'
import useModal from '@src/hooks/useModal'
import styled from 'styled-components'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

export default function FilterSaveContainer(props) {
  const { name, filters, onSaveFilter } = props
  const { visible, handleVisibility } = useModal()
  //eslint-disable-next-line
  const [filterSaveButtonClick, setFilterSaveButtonClick] = useState(false)
  const [validFilter, setValidFilter] = useState(false)
  const [form] = Form.useForm()

  const showModal = (e) => {
    if (filters != null) {
      if (Object.keys(filters).length) {
        setValidFilter(true)
      } else {
        setValidFilter(false)
      }
    }
    setFilterSaveButtonClick(true)
    handleVisibility(true)
  }

  const onFinish = (values) => {
    onSaveFilter(values)
    handleVisibility(false)
    form.resetFields()
  }

  const onFinishFailed = (errorInfo) => {}

  return (
    <div>
      <Button
        id="FilterSave"
        onClick={showModal}
        style={{
          backgroundColor: '#008385',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        Save Filter
      </Button>
      <ModalComponent
        width={500}
        visible={visible}
        handleVisibility={handleVisibility}
        title={
          name === 'parent-consent'
            ? "Parent's Consent Filter Settings"
            : name === 'recalling-sentence'
            ? 'Recalling Sentence Task Filter Settings'
            : name === 'parent-mpone-consent'
            ? "Parent's Measure Package 1 Settings"
            : name === 'participant-mpone-consent'
            ? "Participant's Measure Package 1 Settings"
            : name === 'participant-list'
            ? 'Participant List Filter Settings'
            : "Participant's Consent Filter Settings"
        }
      >
        {validFilter ? (
          <Form
            {...layout}
            form={form}
            name="basic"
            initialValues={{ remember: false }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Filter Name: "
              name="filterName"
              rules={[
                {
                  required: true,
                  message: 'Please provide a name to save the Filter!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <ButtonContainer>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </ButtonContainer>
            </Form.Item>
          </Form>
        ) : (
          <Alert
            message="Please apply the filter on the table then you will be able to save filters!"
            type="warning"
          />
        )}
      </ModalComponent>
    </div>
  )
}
