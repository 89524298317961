import React from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import styled from 'styled-components'

const MainDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: inherit;
`
export default function Loading(props) {
  const { loading } = props
  return (
    <MainDiv>
      <ScaleLoader width={6} color={'#6bb6bc'} loading={loading} />
    </MainDiv>
  )
}
