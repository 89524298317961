/* eslint-disable */
import React, { useState } from 'react'
import { Button, Divider, Tooltip } from 'antd'
import Modal from '@components/modals/index'
import ActionForm from '@components/forms/actions'
import useModal from '@src/hooks/useModal'
import ActionsTable from '@src/components/table/index'
import ActionEditForm from '@components/forms/actions/editAction'
import ActionCompleteMarkForm from '@components/forms/actions/markCompleteAction'
import moment from 'moment'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'

export default function ActionContainer(props) {
  const {
    actions,
    setAction,
    updateAction,
    updateActionComplete,
    participantToDetails,
  } = props

  const { visible, handleVisibility } = useModal()
  const [actionSaved, setActionSaved] = useState(false)
  const [actionClick, setActionClick] = useState(false)
  const [editedData, setEditedData] = useState({})

  const columns = [
    {
      title: 'Completion Date',
      dataIndex: 'completionDate',
      key: 'completionDate',
      width: '12%',
      render: (utcDate) => {
        if (utcDate !== null) {
          return moment(utcDate).format('DD-MM-YYYY')
        } else {
          return <div align="center"> --- </div>
        }
      },
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: '10%',
      render: (utcDate) => {
        if (utcDate !== null) {
          return moment(utcDate).format('DD-MM-YYYY')
        } else {
          return <div align="center"> --- </div>
        }
      },
    },
    { title: 'Note', dataIndex: 'note', key: 'note', width: '30%' },
    {
      title: 'Resolution Note',
      dataIndex: 'resolutionNote',
      key: 'resolutionNote',
      width: '40%',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: () => (
        <span className="table-operation">
          <Tooltip title="Edit An Action">
            <a
              id="ActionEdit"
              style={{ marginRight: 16 }}
              onClick={handleModalVisible}
            >
              <EditOutlined />
            </a>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Make An Action Resolved">
            <a
              id="ActionComplete"
              style={{ marginRight: 16 }}
              onClick={handleModalVisible}
            >
              <CheckOutlined />
            </a>
          </Tooltip>
        </span>
      ),
    },
  ]
  const handleModalVisible = (e) => {
    e.currentTarget.id === 'ActionAdd'
      ? setActionClick(true)
      : e.currentTarget.id === 'ActionEdit'
      ? setActionClick(false)
      : setActionClick(null)
    handleVisibility(true)
  }

  const handleCreate = async (form) => {
    const formData = {
      ...form,
      participantId: participantToDetails.id,
    }
    setAction(formData)
      .then(() => setActionSaved(true))
      .catch(() => setActionSaved(false))
    handleVisibility(false)
  }

  const handleEdit = async (form) => {
    const formData = {
      ...form,
      participantId: participantToDetails.id,
    }
    updateAction(formData)
      .then(() => setActionSaved(true))
      .catch(() => setActionSaved(false))
    handleVisibility(false)
  }

  const handleCompleteAction = async (form) => {
    const formData = {
      ...form,
      participantId: participantToDetails.id,
    }
    updateActionComplete(formData)
      .then(() => setActionSaved(true))
      .catch(() => setActionSaved(false))
    handleVisibility(false)
  }
  return (
    <div>
      <Button
        id="ActionAdd"
        onClick={handleModalVisible}
        type="primary"
        style={{ marginBottom: 16, background: '#008385' }}
      >
        Add
      </Button>

      <Modal
        visible={visible}
        handleVisibility={handleVisibility}
        width={750}
        minHeight={200}
        title={
          actionClick === true
            ? 'Add A New Action'
            : actionClick === false
            ? 'Edit An Action'
            : 'Mark An Action Resolved'
        }
        destroyOnClose
      >
        {actionClick === true ? (
          <ActionForm data={actions} onActionsSubmit={handleCreate} />
        ) : actionClick === false ? (
          <ActionEditForm data={editedData} onUpdateActionSubmit={handleEdit} />
        ) : (
          <ActionCompleteMarkForm
            data={editedData}
            onCompleteActionSubmit={handleCompleteAction}
          />
        )}
      </Modal>

      <ActionsTable
        columns={columns}
        data={actions}
        rowKey="id"
        size="small"
        onRowClick={(row) => ({
          onClick: () => setEditedData(row),
        })}
      />
    </div>
  )
}
