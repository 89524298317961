import { Button, message, Upload, Form, Input, Spin } from 'antd'
import React from 'react'
import { InboxOutlined } from '@ant-design/icons'

export default function UploadComponent(props) {
  const { name, labelType, onUploadClick, loading } = props
  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} is required!',
  }
  const [form] = Form.useForm()
  let msg
  if (name === 'parent-consent') {
    msg = 'Parent Response Survey CSV'
  } else if (name === 'participant-consent') {
    msg = 'Participant Response Survey CSV '
  } else if (name === 'parent-mpone-consent') {
    msg = 'Parent Measure Package 1 Response Survey CSV '
  } else if (name === 'participant-mpone-consent') {
    msg = 'Participant Measure Package 1 Response Survey CSV '
  } else if (name === 'participant-mptwo-consent') {
    msg = 'Participant Measure Package 2 Response Survey CSV '
  } else if (name === 'participant-mpthree-consent') {
    msg = 'Participant Measure Package 3 Response Survey CSV '
  } else if (name === 'parent-mpone-contact') {
    msg = 'Parent Measure Package 1 Correspondence History CSV '
  } else if (name === 'parent-mptwo-contact') {
    msg = 'Parent Measure Package 1 Correspondence History CSV '
  } else if (name === 'participant-mpone-contact') {
    msg = 'Participant Measure Package 1 Correspondence History CSV '
  } else if (name === 'participant-mptwo-contact') {
    msg = 'Participant Measure Package 2 Correspondence History CSV '
  } else if (name === 'participant-mpthree-contact') {
    msg = 'Participant Measure Package 3 Correspondence History CSV '
  } else if (name === 'parent-contact') {
    msg = 'Parent Correspondence History CSV'
  } else if (name === 'participant-contact') {
    msg = 'Participant Correspondence History CSV'
  } else if (name === 'parent-distribution') {
    msg = 'Parent Consent Distribution CSV'
  } else if (name === 'participant-distribution') {
    msg = 'Participant Consent Distribution CSV'
  } else if (name === 'recall-sentence-distribution') {
    msg = 'Recalling Sentence Ids CSV'
  } else if (name === 'recalling-sentence-response') {
    msg = 'Recalling Sentence Response CSV'
  } else if (name === 'recalling-sentence-cor-history') {
    msg = 'Recalling Sentence Correspondence History CSV'
  } else if (name === 'parent-mp1-distribution') {
    msg = 'Parent Measure Package 1 Distribution CSV'
  } else if (name === 'participant-mp1-distribution') {
    msg = 'Participant Measure Package 1 Distribution CSV'
  } else if (name === 'parent-mp2-distribution') {
    msg = 'Parent Mp2 distribution csv'
  } else if (name === 'participant-mp2-distribution') {
    msg = 'Participant MP2 distribution csv'
  } else if (name === 'participant-mp3-distribution') {
    msg = 'Participant MP3 distribution csv'
  }

  const onFinish = (values) => {
    if (!name.includes('distribution')) {
      onUploadClick(values.file[0].originFileObj)
    } else {
      if (
        name === 'parent-distribution' &&
        values.file[0].name.toLowerCase().includes('distribution') &&
        values.file[0].name.toLowerCase().includes('parent')
      ) {
        onUploadClick(values.file[0].originFileObj)
      } else if (
        name === 'participant-distribution' &&
        values.file[0].name.toLowerCase().includes('distribution') &&
        values.file[0].name.toLowerCase().includes('participant')
      ) {
        onUploadClick(values.file[0].originFileObj)
      } else if (
        name === 'recall-sentence-distribution' &&
        values.file[0].name.toLowerCase().includes('recalling')
      ) {
        onUploadClick(values.file[0].originFileObj)
      } else if (
        name === 'parent-mp1-distribution' &&
        values.file[0].name.toLowerCase().includes('parent') &&
        values.file[0].name.toLowerCase().includes('distribution')
      ) {
        onUploadClick(values.file[0].originFileObj)
      } else if (
        name === 'participant-mp2-distribution' &&
        values.file[0].name.toLowerCase().includes('participant') &&
        values.file[0].name.toLowerCase().includes('distribution')
      ) {
        onUploadClick(values.file[0].originFileObj)
      } else if (
        name === 'parent-mp2-distribution' &&
        values.file[0].name.toLowerCase().includes('parent') &&
        values.file[0].name.toLowerCase().includes('distribution')
      ) {
        onUploadClick(values.file[0].originFileObj)
      } else if (
        name === 'participant-mp1-distribution' &&
        values.file[0].name.toLowerCase().includes('participant') &&
        values.file[0].name.toLowerCase().includes('distribution')
      ) {
        onUploadClick(values.file[0].originFileObj)
      } else if (
        name === 'participant-mp3-distribution' &&
        values.file[0].name.toLowerCase().includes('participant') &&
        values.file[0].name.toLowerCase().includes('distribution')
      ) {
        onUploadClick(values.file[0].originFileObj)
      } else {
        message.error(
          `${values.file[0].name} please make sure you are uploading the right file.`
        )
      }
    }
    form.resetFields()
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const formItemLayout = {
    labelCol: {
      span: 18,
    },
    wrapperCol: {
      span: 24,
    },
  }
  const FormLayout = () => {
    return (
      <Form
        {...layout}
        layout="vertical"
        form={form}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          name={['file']}
          valuePropName="filelist"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Please upload CSV file',
            },
          ]}
        >
          <Upload.Dragger
            name="files"
            multiple={false}
            accept=".csv"
            beforeUpload={() => false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              A single <b>{msg}</b>
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="eventType"
          label={'Please Type Keyword (' + labelType + ')'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={labelType} />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button
            style={{
              backgroundColor: '#008385',
              color: 'white',
              fontWeight: 'bold',
              width: '150px',
            }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
  }
  return name === 'parent-distribution' ? (
    <Spin spinning={loading.uploadingDistParent}>
      <FormLayout />
    </Spin>
  ) : name === 'participant-distribution' ? (
    <Spin spinning={loading.uploadingDistParticipant}>
      <FormLayout />
    </Spin>
  ) : name === 'recall-sentence-distribution' ? (
    <Spin spinning={loading.uploadingDistRecallSentence}>
      <FormLayout />
    </Spin>
  ) : name === 'parent-mp1-distribution' ? (
    <Spin spinning={loading.uploadingDistParentMPOne}>
      <FormLayout />
    </Spin>
  ) : name === 'participant-mp1-distribution' ? (
    <Spin spinning={loading.uploadingDistParticipantMPOne}>
      <FormLayout />
    </Spin>
  ) : name === 'parent-mp2-distribution' ? (
    <Spin spinning={loading.uploadingDistParentMPTwo}>
      <FormLayout />
    </Spin>
  ) : name === 'participant-mp2-distribution' ? (
    <Spin spinning={loading.uploadingDistParticipantMPTwo}>
      <FormLayout />
    </Spin>
  ) : name === 'participant-mp3-distribution' ? (
    <Spin spinning={loading.uploadingDistParticipantMPThree}>
      <FormLayout />
    </Spin>
  ) : (
    <div>
      <Spin spinning={loading.uploadingSheet}>
        <FormLayout />
      </Spin>
    </div>
  )
}
