import { Form, Input, Button, Select, Switch, Row, Col, Divider } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 10,
  },
}

const { Option } = Select

function ContactsForm(props) {
  const { onContactSubmit, data } = props

  const [otherCheck, setOtherCheck] = useState(false)

  const handleFinish = (values) => {
    onContactSubmit({ ...values, isPrimaryContact: otherCheck })
  }

  /* eslint-disable */ 
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  /* eslint-enable */

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="61">+61</Option>
        {/*<Option value="64">+64</Option>*/}
      </Select>
    </Form.Item>
  )
  function onChangeSwitch(checked) {
    setOtherCheck(checked)
  }

  return (
    <Form
      name="nest-messages"
      // className="ant-advanced-search-form"
      validateMessages={validateMessages}
      onFinish={handleFinish}
    >
      <Row gutter={24}>
        <Col span={12} key={1}>
          {/*<Form.Item name="id" label="id" hidden={true} rules={[*/}
          {/*{*/}
          {/*initialValue: data && data.id*/}
          {/*},*/}
          {/*]}>*/}
          {/*<Input disabled />*/}
          {/*</Form.Item>*/}
        </Col>
        <Col span={12} key={2}>
          <Form.Item
            label="Primary Contact"
            value={otherCheck}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Switch defaultChecked={otherCheck} onChange={onChangeSwitch} />
          </Form.Item>
        </Col>
        <Col span={12} key={3}>
          <Form.Item
            name={['firstName']}
            label="First Name"
            rules={[
              {
                required: true,
                initialValue: data && data?.firstName,
              },
            ]}
          >
            <Input placeholder="Please input First Name" />
          </Form.Item>
        </Col>
        <Col span={12} key={4}>
          <Form.Item
            name={['lastName']}
            label="Last Name"
            rules={[
              {
                required: true,
                initialValue: data && data?.lastName,
              },
            ]}
          >
            <Input placeholder="Please input Last Name" />
          </Form.Item>
        </Col>
        <Col span={12} key={5}>
          <Form.Item
            name={['relationship']}
            label="Relationship"
            rules={[
              {
                initialValue: data && data?.relationship,
              },
            ]}
          >
            <Input placeholder="Please input Relationship" />
          </Form.Item>
        </Col>
        <Divider orientation="left">Optionals</Divider>
        <Col span={12} key={6}>
          <Form.Item
            name={['email']}
            label="Email"
            rules={[
              {
                // required: true,
                type: 'email',
                // initialValue: data && data.current_contact_details.email,
              },
            ]}
          >
            <Input placeholder="Please input Email" />
          </Form.Item>
        </Col>
        <Col span={12} key={7}>
          <Form.Item
            name={['address']}
            label="Address"
            rules={[
              {
                // required: true,
                // initialValue: data && data.current_contact_details.address,
              },
            ]}
          >
            <Input placeholder="Please input Address" />
          </Form.Item>
        </Col>
        <Col span={12} key={8}>
          <Form.Item
            name={['suburb']}
            label="Suburb"
            rules={[
              {
                // required: true,
                // initialValue: data && data.current_contact_details.suburb,
              },
            ]}
          >
            <Input placeholder="Please input Suburb" />
          </Form.Item>
        </Col>
        <Col span={12} key={9}>
          <Form.Item
            label="State"
            name={['state']}
            rules={[
              {
                // required: true,
                // initialValue: data && data.current_contact_details.state,
              },
            ]}
          >
            <Select placeholder="Select State">
              <Select.Option value="ACT">ACT</Select.Option>
              <Select.Option value="QLD">QLD</Select.Option>
              <Select.Option value="NSW">NSW</Select.Option>
              <Select.Option value="NT">NT</Select.Option>
              <Select.Option value="SA">SA</Select.Option>
              <Select.Option value="TAS">TAS</Select.Option>
              <Select.Option value="VIC">VIC</Select.Option>
              <Select.Option value="WA">WA</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12} key={10}>
          <Form.Item
            name={['postalCode']}
            label="Post Code"
            rules={[
              {
                // required: true,
                message: 'Please input Post Code',
                // initialValue: data && data.current_contact_details.postal_code,
              },
            ]}
          >
            <Input maxLength={4} placeholder="Please input Post Code" />
          </Form.Item>
        </Col>
        <Col span={12} key={11}>
          <Form.Item
            name={['phoneNo']}
            label="Phone #"
            rules={[
              {
                // required: true,
                message: 'Please input Phone #',
                // initialValue: data && data.current_contact_details.phone_no,
              },
            ]}
          >
            <Input
              maxLength={12}
              addonBefore={prefixSelector}
              placeholder="XXXX-XXX-XXX"
            />
          </Form.Item>
        </Col>
        <Col span={12} key={12}>
          <Form.Item
            name={['workPhoneNo']}
            label="Work Phone #"
            rules={[
              {
                // required: true,
                message: 'Please input Work Phone #!',
                // initialValue: data && data.current_contact_details.work_phone_no,
              },
            ]}
          >
            <Input
              maxLength={12}
              addonBefore={prefixSelector}
              placeholder="XXXX-XXX-XXX"
            />
          </Form.Item>
        </Col>
        <Col span={12} key={13}>
          <Form.Item
            name={['mobileNo']}
            label="Mobile #"
            rules={[
              {
                // required: true,
                message: 'Please input Mobile #!',
                // initialValue: data && data.current_contact_details.mobile_no,
              },
            ]}
          >
            <Input
              maxLength={12}
              addonBefore={prefixSelector}
              placeholder="XXXX-XXX-XXX"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <ButtonContainer>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </ButtonContainer>
      </Form.Item>
    </Form>
  )
}
export default ContactsForm
