import { Form, Input, Button, Select, Row, Col } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 10,
  },
}

function MeasurePackage3Form(props) {
  const { onVariablesSubmit, data } = props
  const [form] = Form.useForm()
  const [, forceUpdate] = useState() // To disable submit button at the beginning

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleFinish = (values) => {
    onVariablesSubmit(values)
  }

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} is required!',
    types: {
      // eslint-disable-next-line no-template-curly-in-string
      email: '${label} is not validate email!',
      // eslint-disable-next-line no-template-curly-in-string
      number: '${label} is not a validate number!',
    },
    number: {
      // eslint-disable-next-line no-template-curly-in-string
      range: '${label} must be between ${min} and ${max}',
    },
  }

  return (
    <Form
      form={form}
      name="nest-messages"
      validateMessages={validateMessages}
      onFinish={handleFinish}
      initialValues={{
        id: data?.id,
        mbsPbsAirConsentStatus: data?.mbsPbsAirConsent,
        mbsPbsAirConsentFormSigned: data?.mbsPbsAirConsentFormSigned,
        futureResearchConsentStatus: data?.futureConsentStatus,
        ppiConsentStatus: data?.ppiConsentStatus,
        permanentAddress2023: data?.permanentAddress2023,
        postalAddress2023: data?.postalAddress2023,
      }}
    >
      <Form.Item name="id" label="id" hidden>
        <Input disabled />
      </Form.Item>
      <Row gutter={24}>
        <Col span={18} key={1}>
          <Form.Item
            name={['mbsPbsAirConsentStatus']}
            label="MBS/PBS/AIR Consent Status"
          >
            <Select placeholder="Select status">
              <Select.Option value="1">Yes</Select.Option>
              <Select.Option value="0">No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} />
        <Col span={18} key={2}>
          <Form.Item
            name={['mbsPbsAirConsentFormSigned']}
            label="MBS/PBS/AIR Consent Form Signed"
          >
            <Select placeholder="Select status">
              <Select.Option value="1">Yes</Select.Option>
              <Select.Option value="0">No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} />
        <Col span={18} key={3}>
          <Form.Item
            name={['futureResearchConsentStatus']}
            label="Future Consent Status"
          >
            <Select placeholder="Select status">
              <Select.Option value="1">Yes</Select.Option>
              <Select.Option value="0">No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} />
        <Col span={18} key={4}>
          <Form.Item name={['ppiConsentStatus']} label="PPI Consent Status">
            <Select placeholder="Select status">
              <Select.Option value="1">Yes</Select.Option>
              <Select.Option value="0">No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} />
        <Col span={24} key={5}>
          <Form.Item name={['permanentAddress2023']} label="Permanent Address 2023">
            <Input placeholder="Please input address" />
          </Form.Item>
        </Col>
        <Col span={24} key={5}>
          <Form.Item name={['postalAddress2023']} label="Postal Address 2023">
            <Input placeholder="Please input postal address" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} shouldUpdate>
        {() => (
          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                (!form.isFieldTouched('mbsPbsAirConsentStatus') &&
                  !form.isFieldTouched('mbsPbsAirConsentFormSigned') &&
                  !form.isFieldTouched('futureResearchConsentStatus') &&
                  !form.isFieldTouched('ppiConsentStatus') &&
                  !form.isFieldTouched('permanentAddress2023') &&
                  !form.isFieldTouched('postalAddress2023')) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Submit
            </Button>
          </ButtonContainer>
        )}
      </Form.Item>
    </Form>
  )
}
export default MeasurePackage3Form
