export const PROMISE_STATUS = {
  FULFILLED: 'FULFILLED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
}

export const API = {
  DELAY_TIME: 500,
}

export const UNKNOWN_ERROR = 'Unknown error'

export const USER_MSG = {
  REGISTER_USER_SUCCESS_MSG: 'User has been registered successfully!',
  ADD_NEW_USER_SUCCESS_MSG: 'User has been added successfully!',
  LOGOUT_USER_SUCCESS_MSG: 'User logout successfully!',
  EDIT_USER_DETAIL_MSG: 'User details has been saved successfully!',
  EDIT_USER_OWN_PASSWORD_MSG: 'User password has been changed successfully!',
  EDIT_OTHER_USER_PASSWORD_MSG: 'User password has been changed successfully!',
}

export const PARTICIPANT_MSGS = {
  ADD_COMM_LOG_MSG: 'Communication log has been added successfully!',
  EDIT_COMM_LOG_MSG: 'Communication log has been saved successfully!',
  ADD_ACTIONS_MSG: 'Action has been added successfully!',
  EDIT_ACTIONS_MSG: 'Action has been saved successfully!',
  ADD_COMMENT_MSG: 'Comment has been added successfully!',
  EDIT_COMMENT_MSG: 'Comment has been saved successfully!',
  SET_OTHER_CONTACT_PRIMARY_MSG: 'Other contact has been marked as Primary',
  ADD_CONTACT_MSG: 'Contact details have been added successfully!',
  EDIT_CONTACT_MSG: 'Contact details have been saved successfully!',
  EDIT_SCHOOL_MSG: 'School details have been saved successfully!',
  EDIT_DETAIL_MSG: 'Details have been saved successfully!',
  EDIT_PARTICIPANT_CONTACT_STATUS_SUCCESS_MSG:
    'Participant Contact Status has been updated',
}

export const PARENT_MSGS = {
  EDIT_PARENT_CONTACT_STATUS_SUCCESS_MSG: 'Parent Contact Status has been updated',
}

export const RECALLING_SENT_MSGS = {
  EDIT_RECALLING_SENTENCE_CONTACT_STATUS_SUCCESS_MSG:
    'Recalling Sentence Status has been updated',
}

export const PARENT_MP1_MSGS = {
  EDIT_PARENT_MP1_CONTACT_STATUS_SUCCESS_MSG:
    'Parent Measure Package 1 Contact Status has been updated',
}

export const PARTICIPANT_MP1_MSGS = {
  EDIT_PARTICIPANT_MP1_CONTACT_STATUS_SUCCESS_MSG:
    'Participant Measure Package 1 Contact Status has been updated',
}

export const PARENT_MP2_MSGS = {
  EDIT_PARENT_MP2_CONTACT_STATUS_SUCCESS_MSG:
    'Parent Measure Package 1 Contact Status has been updated',
}

export const PARTICIPANT_MP2_MSGS = {
  EDIT_PARTICIPANT_MP2_CONTACT_STATUS_SUCCESS_MSG:
    'Participant Measure Package 1 Contact Status has been updated',
}

export const SURVEYS = {
  EDIT_CONTACT_STATUS: 'SURVEYS.EDIT_CONTACT_STATUS',
  DELETE_SURVEY: 'SURVEYS.DELETE_SURVEY',
}

export const PARTICIPANT_MP3_MSGS = {
  EDIT_PARTICIPANT_MP3_CONTACT_STATUS_SUCCESS_MSG:
    'Participant Measure Package 3 Contact Status has been updated',
  EDIT_PARTICIPANT_MP3_VARIABLES_STATUS_SUCCESS_MSG:
    'Participant Measure Package 3 Variables Status has been updated',
}
