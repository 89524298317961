import EP from './endpoints'
import api from './api'

export function login(email, password) {
  const data = {
    email,
    password,
  }
  return api.post(EP.USER_LOGIN(), data)
}

export function logout() {
  return api.delete(EP.USER_LOGOUT())
}

export function registerNewUser(email, password, name) {
  return api.post(EP.USER_REGISTER(), { email, password, name })
}

export function fetchAllUsers() {
  return api.get(EP.ALL_USERS())
}

export function addNewUser(data) {
  return api.post(EP.USER_REGISTER(), data)
}

export function editUser(id, data) {
  return api.put(EP.SET_USER_DETAILS(id), data)
}

export function resetOtherUserPassword(id) {
  return api.get(EP.SET_OTHER_USER_PASSWORD(id))
}

export function downloadBulkWorksheet() {
  return api.get(EP.GET_DOWNLOAD_BULK_WORKSHEET())
}
