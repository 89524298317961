/* eslint-disable */
import React from 'react'
import { Layout, Menu, Dropdown, Button, Spin, Alert } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logOutUser, getBulkDataToDownload } from '@actions/userActions'
import ROUTES from '@constants/routes'
import {
  ProfileOutlined,
  LogoutOutlined,
  DownOutlined,
  UserOutlined,
  ToolOutlined,
  FileTextOutlined,
  SettingOutlined,
  DownloadOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons'

import useModal from '@src/hooks/useModal'
import ModalComponent from '@components/modals'

const { Header } = Layout

const HeaderDiv = styled.div`
  display: flex;
  color: white;
  background: #008385;
`

const HeaderLogo = styled.div`
  padding-left: 25px;
  width: 500px;
  font-size: 20px;
`

const HeaderBuffer = styled.div`
  flex-grow: 2;
`

const HeaderUserInfo = styled.div`
  padding-right: 30px;
  display: flex;
  flex-direction: row;
`

function HeaderContainer(props) {
  const { user, loading, dispatchOnLogout, downloadBulkData } = props

  const { visible, handleVisibility } = useModal()

  const showModal = (e) => {
    handleVisibility(true)
  }
  const onDownloadClick = (e) => {
    downloadBulkData()
  }
  const menu = (
    <Menu>
      <Menu.Item key={ROUTES.distributionHistory}>
        <Link to={ROUTES.distributionHistory}>
          <span>
            <FileTextOutlined /> Survey/Task Linkage Utility
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key={ROUTES.userManagement}>
        <Link to={ROUTES.userManagement}>
          <span>
            <SettingOutlined /> User Management
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key={ROUTES.downloadAllData}>
        <Link to={ROUTES.downloadAllData} onClick={showModal}>
          <span>
            <DownloadOutlined /> Bulk Download
          </span>
          <ModalComponent
            width={1000}
            visible={visible}
            handleVisibility={handleVisibility}
            title="Bulk Download Utility"
          >
            <div>
              <Spin spinning={loading.downloadWorksheet}>
                <Alert
                  message="Note for Download"
                  description="This utility will download All Data"
                  type="info"
                  showIcon
                ></Alert>
              </Spin>
            </div>
            <div style={{ paddingTop: '20px' }}>
              <Button
                style={{
                  backgroundColor: '#008385',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                onClick={onDownloadClick}
                disabled={loading.downloadWorksheet}
              >
                <CloudDownloadOutlined /> Download
              </Button>
            </div>
          </ModalComponent>
        </Link>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key="2" onClick={() => dispatchOnLogout()}>
        {' '}
        <span>
          {' '}
          <LogoutOutlined /> Log Out
        </span>
      </Menu.Item>
    </Menu>
  )
  return (
    <Header style={{ padding: 0 }}>
      <div className="logo" />
      <Menu theme="dark" mode="horizontal" />
      <HeaderDiv>
        <HeaderLogo>
          <Link to="/">
            <ProfileOutlined style={{ color: 'white' }} />
            <span style={{ color: 'white' }}>
              {' '}
              Early Language in Victoria Study (ELVS)
            </span>
          </Link>
        </HeaderLogo>
        <HeaderBuffer />
        {user.user && user.user.accessToken && (
          <HeaderUserInfo>
            <div style={{ color: 'white' }}>
              <UserOutlined />
              <span style={{ marginLeft: 10 }}>{user.user.name}</span>
            </div>
            <div style={{ marginLeft: 20 }}>
              <Dropdown overlay={menu}>
                <a style={{ color: 'white' }} onClick={(e) => e.preventDefault()}>
                  <ToolOutlined /> <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </HeaderUserInfo>
        )}
      </HeaderDiv>
    </Header>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
    loading: state.user.loading,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchOnLogout: () => dispatch(logOutUser()),
    downloadBulkData: () => dispatch(getBulkDataToDownload()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
